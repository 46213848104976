import React, { useEffect, useReducer, Reducer, useState } from 'react';

import { useParams } from 'react-router-dom';
import PMSBreadcrumb from '../SharedComponents/PMSBreadcrumb.component';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Loading, useNotify } from 'react-admin';
import { TEAM_PMS_PATH } from '../constant';
import {
	useGetReporteeAppraisalDetailsQuery,
	useUpdateManagerFeedbackMutation,
	useUpdateMangerReviewRatingsMutation,
} from '../../../generated/graphql';
import { head } from 'lodash';
import { formStyle, pmsViewDetailsStyle } from '../pms.styles';
import { DEFAULT_PROFILE_PIC } from '../../../config/constant';
import { Avatar, Button, ThemeProvider, Tooltip } from '@material-ui/core';
import { ellipsisStyle } from '../../../Layout/styles';
import { getExperience } from '../../../Utils/date-time.util';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { pmsGoalReviewGridTheme } from '../pmsTheme';
import {
	PMS_ACTIONS,
	PMS_APPRAISAL_STATUS,
	PMS_USER_APPRAISAL_STATUS,
	RATING_UPDATED,
	SUBMITTED_MANAGER_EVALUATION,
} from '../constant';
import GoalRatingInput from '../SharedComponents/GoalRatingInput.component';
import { GoalReview, TableData } from '../pms.module';
import {
	loadGoalsForManagerReview,
	updateGoalRatingByManager,
} from '../pmsReducer.util';
import Loader from '../SharedComponents/Loader.component';
import InfoIcon from '@material-ui/icons/Info';
import PMSFeedbackForm from '../SharedComponents/PMSFeedbackForm.component';
import dayjs from 'dayjs';
import ReviewGoalComment from './ReviewGoalComment';
import CommentIcon from '@material-ui/icons/Comment';
import PMSManagerFeedbackForm from '../SharedComponents/PMSManagerFeedbackForm.component';
import { getEndDate } from '../pms.utils';

const tableInitialState: TableData = {
	isDisabled: false,
	isSubmissionHidden: false,
	isSaved: true,
	data: [],
};

type Action = {
	type: string;
	payload: any;
};

const reducer: Reducer<any, Action> = (state: TableData, action: any) => {
	switch (action.type) {
		case PMS_ACTIONS.LOAD_GOALS: {
			return loadGoalsForManagerReview(action.payload.data);
		}
		case PMS_ACTIONS.UPDATE_GOAL_RATING_BY_MANAGER: {
			return updateGoalRatingByManager(
				state,
				action.payload.goalMappingId,
				action.payload.value,
				action.payload.initialManagerReview
			);
		}
	}
};

function ReporteePMSView() {
	const {
		appraisalCycleId,
		userId,
	}: { appraisalCycleId: string; userId: string } = useParams();
	const pmsDetailsStyles = pmsViewDetailsStyle();
	const ellipsis = ellipsisStyle();
	const formStyles = formStyle();
	const notify = useNotify();

	const [initialManagerRatingValues, setInitialManagerRatingValues] = useState<
		GoalReview[]
	>([]);
	const [tableData, dispatch] = useReducer(reducer, tableInitialState);
	const [isFeedbackFormShown, setIsFeedbackFormShown] = useState(false);
	const [isGoalCommentModalOpen, setIsGoalCommentModalOpen] = useState(false);
	const [goalMapping, setGoalMapping] = useState<{
		id: any;
		user_comment: string;
		reviewer_comment: string;
		isDisabled: boolean;
		goal: string;
		index: number;
	}>({
		id: '',
		user_comment: '',
		reviewer_comment: '',
		isDisabled: true,
		goal: '',
		index: 0,
	});

	// API CALL
	const {
		data: userAppraisalDetails,
		loading: isUserDetailsLoading,
		refetch: refetchUserAppraisalDetails,
	} = useGetReporteeAppraisalDetailsQuery({
		variables: {
			appraisalCycleId: appraisalCycleId,
			userId: userId,
		},
	});

	const [updateMangerRatings, { loading: isMangerRatingUpdateLoading }] =
		useUpdateMangerReviewRatingsMutation();

	const [updateManagerFeedback, { loading: isManagerFeedbackLoading }] =
		useUpdateManagerFeedbackMutation();

	useEffect(() => {
		if (!userAppraisalDetails) return;

		const isManagerReviewCompleted =
			head(userAppraisalDetails?.pms_pms_appraisal_user_mapping)
				?.pms_user_appraisal_status.value ===
				PMS_USER_APPRAISAL_STATUS.APPRAISAL_MANAGER_REVIEW_COMPLETED ||
			head(userAppraisalDetails?.pms_pms_appraisal_user_mapping)
				?.pms_user_appraisal_status.value ===
				PMS_USER_APPRAISAL_STATUS.APPRAISAL_CLOSED;

		const initialGoalReviews = userAppraisalDetails?.goals.map((goal) => ({
			isDisabled: isManagerReviewCompleted,
			isError: false,
			goalMappingId: goal.id,
			value: `${goal.manager_review}` || '0',
			errorText: '',
		}));

		setInitialManagerRatingValues(initialGoalReviews);

		dispatch({
			type: PMS_ACTIONS.LOAD_GOALS,
			payload: {
				data: userAppraisalDetails,
			},
		});
	}, [userAppraisalDetails]);

	const getEmployeeDetails = () => {
		if (!userAppraisalDetails) return;

		const joinDate =
			head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
				.user_type === 'employee'
				? head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
						.employee?.join_date
				: head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
						.contractors[0].join_date;

		const priorExperience =
			head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
				.user_type === 'employee'
				? head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
						.employee?.prior_experience
				: '00:00:00';

		return {
			name:
				head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
					.full_name || '- -',
			experience: getExperience({
				join_date: joinDate,
				prior_experience: priorExperience,
			}),
			profile_pic: head(userAppraisalDetails.pms_pms_appraisal_user_mapping)
				?.user.profile_pic,
			status:
				head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
					.user_type === 'employee'
					? head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
							.employee?.employee_status?.label
					: head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
							.contractors[0].contractorStatus?.label,
			join_date: joinDate,
			prior_experience: priorExperience,
			job_level:
				head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
					.user_type === 'employee'
					? head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
							.employee?.job_level?.level || '- -'
					: head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
							.contractors[0]?.job_level?.level || '- -',
			designation:
				head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
					.user_type === 'employee'
					? head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
							.employee?.designation?.title || '- -'
					: head(userAppraisalDetails.pms_pms_appraisal_user_mapping)?.user
							.contractors[0]?.designation?.title || '- -',
		};
	};

	const getTableData = () => {
		if (!userAppraisalDetails) return tableInitialState;

		const isManagerReviewCompleted =
			head(userAppraisalDetails?.pms_pms_appraisal_user_mapping)
				?.pms_user_appraisal_status.value ===
				PMS_USER_APPRAISAL_STATUS.APPRAISAL_MANAGER_REVIEW_COMPLETED ||
			head(userAppraisalDetails?.pms_pms_appraisal_user_mapping)
				?.pms_user_appraisal_status.value ===
				PMS_USER_APPRAISAL_STATUS.APPRAISAL_CLOSED;

		const isAppraisalClosed =
			head(userAppraisalDetails.appraisalCycle)?.pms_appraisal_status.value ===
			PMS_APPRAISAL_STATUS.APPRAISAL_CLOSED
				? true
				: false;

		const data = userAppraisalDetails.goals.map((goal) => ({
			goal: {
				name: goal.pms_master_goal.name || '- -',
				description: goal.pms_master_goal.description || '- -',
			},
			weightage: goal.weightage || 0,
			selfReview: {
				isDisabled: true,
				isError: false,
				goalMappingId: goal.id,
				value: goal.progress || 0,
			},
			managerReview: {
				isDisabled: isManagerReviewCompleted,
				isError: false,
				goalMappingId: goal.id,
				value: `${goal.manager_review}` || '0',
			},
			checkins: goal.checkIns.map((checkIn) => ({
				progress: checkIn.progress,
				comment: checkIn.comment,
				doc_url: checkIn.doc_url,
			})),
		}));

		return {
			isDisabled: isAppraisalClosed,
			isSubmissionHidden: isManagerReviewCompleted,
			isSaved: true,
			data: data,
		};
	};

	const columns = [
		{
			name: 'goal',
			label: 'GOAL',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<>
							<Tooltip title={value.name || ''} placement='top'>
								<div
									className={`${pmsDetailsStyles.goal} ${ellipsis.ellipsis}`}
								>
									{value.name || ''}
								</div>
							</Tooltip>
							<Tooltip title={value.description || ''} placement='top'>
								<div
									className={`${pmsDetailsStyles.goalDescription} ${ellipsis.ellipsis}`}
								>
									{value.description || ''}
								</div>
							</Tooltip>
						</>
					);
				},
			},
		},
		{
			name: 'target',
			label: 'TARGET',
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: 'target_achieved',
			label: 'TARGET ACHIEVED',
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: 'weightage',
			label: 'WEIGHTAGE(%)',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div className={pmsDetailsStyles.goalWeightage}>{value || 0}</div>
					);
				},
			},
		},
		{
			name: 'selfReview',
			label: 'SELF REVIEW',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<GoalRatingInput
							size={'small'}
							isDisabled={true}
							iconVisiblity={false}
							value={value.value}
							isError={false}
							onChange={async (e) => {}}
						/>
					);
				},
			},
		},
		{
			name: 'managerReview',
			label: 'MANAGER REVIEW',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<GoalRatingInput
							size={'small'}
							isDisabled={value.isDisabled}
							iconVisiblity={false}
							value={value.value}
							isError={value.isError}
							errorText={value.errorText}
							onChange={async (e: any) => {
								dispatch({
									type: PMS_ACTIONS.UPDATE_GOAL_RATING_BY_MANAGER,
									payload: {
										goalMappingId: value.goalMappingId,
										value: e.target.value,
										initialManagerReview: initialManagerRatingValues,
									},
								});
							}}
						/>
					);
				},
			},
		},
		{
			name: 'action',
			label: 'COMMENT',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<CommentIcon
							className={pmsDetailsStyles.commentIcon}
							onClick={() => {
								setGoalMapping(value);
								setIsGoalCommentModalOpen(true);
							}}
						/>
					);
				},
			},
		},
	];

	const options: MUIDataTableOptions = {
		filter: false,
		responsive: 'standard',
		print: false,
		searchAlwaysOpen: false,
		search: false,
		serverSide: false,
		viewColumns: false,
		expandableRows: false,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		selectableRowsHeader: false,
		selectableRowsHideCheckboxes: true,
		pagination: false,
		tableBodyHeight: '100%',
		textLabels: {
			body: {
				noMatch: 'No data found',
			},
		},

		renderExpandableRow: (rowData: any, rowMeta: any) => {
			const tableData = getTableData();
			const checkins = tableData
				? tableData?.data[rowMeta.dataIndex].checkins
				: [];
			return (
				<>
					{checkins.length > 0 ? (
						checkins.map((v: any) => <div></div>)
					) : (
						<div>No Check In Data</div>
					)}
				</>
			);
		},
	};

	const getMangerRating = () => {
		const maxRating = head(
			head(userAppraisalDetails?.appraisalCycle)?.pms_appraisal_rating_scales
		)?.max;
		const totalRating =
			tableData.data
				.map((goalMapping: any) => {
					const managerRating =
						(goalMapping.weightage / 100) *
						Number(goalMapping.managerReview.value);
					return (maxRating / 100) * managerRating;
				})
				.reduce((sum: number, value: Number) => {
					return Number(value) + sum;
				}, 0) || 0;

		return Number(totalRating.toFixed(1));
	};

	const getOverAllRating = () => {
		const totalRating = getMangerRating();

		const ratingScales = head(
			userAppraisalDetails?.appraisalCycle
		)?.pms_appraisal_rating_scales;
		if (ratingScales) {
			const rating = ratingScales.find(
				(rating) => rating.min <= totalRating && totalRating <= rating.max
			);
			if (rating) {
				return `${rating.label}`;
			}
		}

		return '- -';
	};

	const getRatingScaleInfo = () => {
		if (!userAppraisalDetails) return '';
		const ratingScales = head(
			userAppraisalDetails?.appraisalCycle
		)?.pms_appraisal_rating_scales.map(
			(ratingScale) =>
				`(${ratingScale.min} - ${ratingScale.max}) - ${ratingScale.label}`
		);
		return ratingScales?.map((v) => <p>{v}</p>);
	};

	const isTableHasError = () =>
		tableData.data
			.map((goalMapping: any) => goalMapping.managerReview.isError)
			.includes(true);

	const handleSaveMangerRating = () => {
		const payload = tableData.data.map((goalMapping: any) => ({
			id: goalMapping.managerReview.goalMappingId,
			goal_id: goalMapping.goal.id,
			appraisal_cycle_id: appraisalCycleId,
			user_id: goalMapping.userId,
			assigned_by: goalMapping.assignedBy,
			unit: goalMapping.unit,
			weightage: goalMapping.weightage,
			start_date: goalMapping.startDate,
			end_date: goalMapping.endDate,
			progress: Number(goalMapping.selfReview.value),
			goal_status_id: goalMapping.goalStatusId,
			manager_review: Number(goalMapping.managerReview.value),
		}));

		updateMangerRatings({
			variables: {
				data: payload,
			},
		}).then((res) => {
			if (!res.errors) {
				notify(RATING_UPDATED);
				refetchUserAppraisalDetails();
			}
		});
	};

	const handleManagerReviewFeedback = (feedback: string) => {
		if (!feedback) return;

		const managerReviewCompletedStatus =
			userAppraisalDetails?.pms_pms_user_appraisal_status.find(
				(status) =>
					status.value ===
					PMS_USER_APPRAISAL_STATUS.APPRAISAL_MANAGER_REVIEW_COMPLETED
			)?.id;

		if (managerReviewCompletedStatus) {
			updateManagerFeedback({
				variables: {
					data: [
						{
							user_id: userId,
							appraisal_cycle_id: appraisalCycleId,
							reviewer_comment: feedback,
							manager_rating: getOverAllRating(),
							manager_rating_value: getMangerRating(),
						},
					],
					user_appraisal_status_id: managerReviewCompletedStatus,
					appraisalCycleId: appraisalCycleId,
					userId: userId,
				},
			}).then((res) => {
				if (!res.errors) {
					refetchUserAppraisalDetails();
					setIsFeedbackFormShown(false);
					notify(SUBMITTED_MANAGER_EVALUATION);
				}
			});
		}
	};

	const getPreviousCycleRatings = () => {
		if (!userAppraisalDetails) return [];

		const currentAppraisalCycle = head(userAppraisalDetails?.appraisalCycle);
		return userAppraisalDetails?.previousAppraisalCycles
			.filter((appraisalCycle) =>
				dayjs(appraisalCycle.pms_appraisal_cycle.end_date).isBefore(
					dayjs(currentAppraisalCycle?.end_date)
				)
			)
			.map((cycle) => ({
				name: cycle.pms_appraisal_cycle.name,
				period: `${dayjs(cycle.pms_appraisal_cycle.start_date).format(
					'MMM DD, YYYY'
				)} - ${dayjs(cycle.pms_appraisal_cycle.end_date).format(
					'MMM DD, YYYY'
				)}`,
				rating: !cycle?.manager_rating ? '' : cycle?.manager_rating,
				ratingScore: !cycle?.manager_rating_value
					? '- -'
					: `(${cycle.manager_rating_value}/${cycle.pms_appraisal_cycle.pms_appraisal_rating_scales[0].max})`,
			}));
	};

	return (
		<div>
			<PMSBreadcrumb
				data={[
					{ ...TEAM_PMS_PATH },
					{
						name: getEmployeeDetails()?.name || 'Appraisal Cycle Detail',
					},
				]}
				icon={DoubleArrowIcon}
			/>

			{isUserDetailsLoading ? (
				<Loading />
			) : (
				<div>
					<div className={pmsDetailsStyles.container}>
						<div
							className={`${pmsDetailsStyles.flex} ${pmsDetailsStyles.profileOuterContainer}`}
						>
							<div className={`${pmsDetailsStyles.profileContainer}`}>
								<div className={`${pmsDetailsStyles.flex}`}>
									<div className={pmsDetailsStyles.profileDetailsLeftContainer}>
										<Avatar
											alt='employee_image'
											src={`${
												getEmployeeDetails()?.profile_pic || DEFAULT_PROFILE_PIC
											}`}
											className={pmsDetailsStyles.profilePic}
										/>
										<Tooltip
											title={`${getEmployeeDetails()?.name || '- -'}`}
											placement='right'
										>
											<p
												className={`${pmsDetailsStyles.profileName} ${ellipsis.ellipsis}`}
											>
												{getEmployeeDetails()?.name}
											</p>
										</Tooltip>
										<Tooltip
											title={`${getEmployeeDetails()?.designation || '- -'}`}
											placement='right'
										>
											<p
												className={`${pmsDetailsStyles.profileDetailsLabel} ${ellipsis.ellipsis}`}
											>
												{getEmployeeDetails()?.designation || '- -'}
											</p>
										</Tooltip>
									</div>

									<div className={pmsDetailsStyles.employeeDetailsContainer}>
										<div>
											<p className={`${pmsDetailsStyles.profileDetailsLabel}`}>
												Year Of Experience
											</p>
											<p className={pmsDetailsStyles.profileDetailsValue}>
												{getEmployeeDetails()?.join_date
													? getEmployeeDetails()?.experience.totalExp
													: 0}
											</p>
										</div>
										<div>
											<p className={`${pmsDetailsStyles.profileDetailsLabel}`}>
												Job Level
											</p>
											<p className={pmsDetailsStyles.profileDetailsValue}>
												{getEmployeeDetails()?.job_level || '- -'}
											</p>
										</div>
										<div>
											<p className={`${pmsDetailsStyles.profileDetailsLabel}`}>
												Employee Status
											</p>
											<p className={pmsDetailsStyles.profileDetailsValue}>
												{getEmployeeDetails()?.status || '- -'}
											</p>
										</div>
										<div>
											<p className={`${pmsDetailsStyles.profileDetailsLabel}`}>
												Appraisal Eligibility
											</p>
											<p className={pmsDetailsStyles.profileDetailsValue}>
												Eligible
											</p>
										</div>
									</div>
								</div>
							</div>
							<div
								className={pmsDetailsStyles.profileContainer}
								style={{ width: '500px' }}
							>
								{/* Header */}
								<div className={pmsDetailsStyles.row}>
									<p className={pmsDetailsStyles.profileDetailsLabel}>
										Appraisal
									</p>
									<p className={pmsDetailsStyles.profileDetailsLabel}>Period</p>
									<p className={pmsDetailsStyles.profileDetailsLabel}>Rating</p>
								</div>

								{/* Rows */}
								{getPreviousCycleRatings()?.length > 0 ? (
									getPreviousCycleRatings()?.map((previousCycle) => (
										<div className={pmsDetailsStyles.row}>
											<Tooltip
												placement='right'
												title={`${previousCycle.name || ''}`}
											>
												<p
													className={`${pmsDetailsStyles.profileDetailsValue} ${ellipsis.ellipsis}`}
													style={{
														width: '124px',
													}}
												>
													{previousCycle.name}
												</p>
											</Tooltip>
											<p className={pmsDetailsStyles.profileDetailsValue}>
												{previousCycle.period}
											</p>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<Tooltip
													placement='right'
													title={`${previousCycle.rating || ''}`}
												>
													<p
														className={`${pmsDetailsStyles.profileDetailsValue} ${ellipsis.ellipsis}`}
														style={{
															width: '74px',
														}}
													>
														{previousCycle.rating}
													</p>
												</Tooltip>
												<p className={pmsDetailsStyles.profileDetailsValue}>
													{previousCycle.ratingScore}
												</p>
											</div>
										</div>
									))
								) : (
									<div
										style={{
											width: '100%',
											height: '60%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<p className={pmsDetailsStyles.profileDetailsValue}>
											No Data Found
										</p>
									</div>
								)}
							</div>
							{/* TODO Implement Skill progress chart */}
							{/* <div
								className={`${pmsDetailsStyles.profileContainer} ${pmsDetailsStyles.flex}`}
							>
								<SkillProgressChart />
							</div> */}
							<div
								className={`${pmsDetailsStyles.profileContainer}`}
								style={{
									width: '400px',
								}}
							>
								<div
									className={pmsDetailsStyles.ratingContainer}
									style={{
										display: 'block',
										padding: '10px 2px',
									}}
								>
									<div>
										<div>
											<div className={pmsDetailsStyles.ratingContainerRow}>
												<p className={pmsDetailsStyles.ratingContainerValues}>
													Appraisal Status
												</p>

												<div className={pmsDetailsStyles.ratingContainerValues}>
													<Tooltip
														placement='left'
														title={
															head(userAppraisalDetails?.appraisalCycle)
																?.pms_appraisal_status.label || '- -'
														}
													>
														<p
															className={`${pmsDetailsStyles.appraisalStatusChip} ${ellipsis.ellipsis}`}
															style={{
																maxWidth: '130px',
															}}
														>
															{head(userAppraisalDetails?.appraisalCycle)
																?.pms_appraisal_status.label || '- -'}
														</p>
													</Tooltip>
												</div>
											</div>
											<div className={pmsDetailsStyles.ratingContainerRow}>
												<p className={pmsDetailsStyles.ratingContainerValues}>
													Review End date
												</p>

												<p className={pmsDetailsStyles.ratingContainerValues}>
													{getEndDate(
														head(userAppraisalDetails?.appraisalCycle)
															?.review_end_date
													)}
												</p>
											</div>
											<div className={pmsDetailsStyles.ratingContainerRow}>
												<div className={pmsDetailsStyles.flex}>
													<p className={pmsDetailsStyles.ratingContainerValues}>
														Self Rating
													</p>
													<Tooltip
														title={getRatingScaleInfo() || ''}
														placement='bottom'
													>
														<InfoIcon className={pmsDetailsStyles.infoIcon} />
													</Tooltip>
												</div>

												{head(
													userAppraisalDetails?.pms_pms_appraisal_user_mapping
												)?.user.appraisalFeedbacks[0]?.self_rating ? (
													<div className={pmsDetailsStyles.flex}>
														<Tooltip
															placement='left'
															title={
																head(
																	userAppraisalDetails?.pms_pms_appraisal_user_mapping
																)?.user.appraisalFeedbacks[0].self_rating ||
																'- -'
															}
														>
															<p
																className={`${pmsDetailsStyles.ratingContainerValues} ${ellipsis.ellipsis}`}
																style={{
																	maxWidth: '130px',
																	fontWeight: 'bold',
																}}
															>
																{head(
																	userAppraisalDetails?.pms_pms_appraisal_user_mapping
																)?.user.appraisalFeedbacks[0].self_rating ||
																	'- -'}
															</p>
														</Tooltip>
														<p
															className={pmsDetailsStyles.ratingContainerValues}
															style={{
																fontWeight: 'bold',
															}}
														>
															{`(${
																head(
																	userAppraisalDetails?.pms_pms_appraisal_user_mapping
																)?.user.appraisalFeedbacks[0]
																	.self_rating_value || 0
															})`}
														</p>
													</div>
												) : (
													'- -'
												)}
											</div>
											<div className={pmsDetailsStyles.ratingContainerRow}>
												<div className={pmsDetailsStyles.flex}>
													<p className={pmsDetailsStyles.ratingContainerValues}>
														Manager Rating
													</p>
													<Tooltip
														title={getRatingScaleInfo() || ''}
														placement='bottom'
													>
														<InfoIcon className={pmsDetailsStyles.infoIcon} />
													</Tooltip>
												</div>

												<p className={pmsDetailsStyles.ratingContainerValues}>
													<div className={pmsDetailsStyles.flex}>
														<Tooltip
															placement='left'
															title={getOverAllRating()}
														>
															<p
																className={`${pmsDetailsStyles.ratingContainerValues} ${ellipsis.ellipsis}`}
																style={{
																	maxWidth: '130px',
																	fontWeight: 'bold',
																}}
															>
																{getOverAllRating()}
															</p>
														</Tooltip>
														<p
															className={pmsDetailsStyles.ratingContainerValues}
															style={{
																fontWeight: 'bold',
															}}
														>
															{`(${getMangerRating()})`}
														</p>
													</div>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* CONTENT */}
						<div>
							<ThemeProvider theme={pmsGoalReviewGridTheme}>
								<MUIDataTable
									title={'GOALS'}
									data={tableData.data || []}
									columns={columns}
									options={options}
								/>
							</ThemeProvider>
						</div>
					</div>
				</div>
			)}
			<div className={pmsDetailsStyles.buttonContainer}>
				<div>
					{!tableData?.isSubmissionHidden && !tableData?.isDisabled && (
						<Button
							disabled={
								isTableHasError() ||
								!tableData?.isSaved ||
								isMangerRatingUpdateLoading
							}
							className={
								isTableHasError() ||
								!tableData?.isSaved ||
								isMangerRatingUpdateLoading
									? formStyles.disabledButton
									: formStyles.saveButton
							}
							onClick={() => {
								setIsFeedbackFormShown(true);
							}}
						>
							Submit Manager Review
						</Button>
					)}
				</div>

				<div>
					{!tableData?.isSubmissionHidden && !tableData?.isDisabled && (
						<Button
							disabled={
								isTableHasError() ||
								tableData?.isSaved ||
								isMangerRatingUpdateLoading
							}
							className={
								isTableHasError() ||
								tableData?.isSaved ||
								isMangerRatingUpdateLoading
									? formStyles.disabledButton
									: formStyles.saveButton
							}
							onClick={handleSaveMangerRating}
							startIcon={isMangerRatingUpdateLoading && <Loader />}
						>
							Save
						</Button>
					)}
				</div>
			</div>
			<PMSFeedbackForm
				onClose={() => {
					setIsFeedbackFormShown(false);
				}}
				open={isFeedbackFormShown}
				details={{
					appraisalName:
						head(userAppraisalDetails?.appraisalCycle)?.name || '- -',
					userName:
						head(userAppraisalDetails?.pms_pms_appraisal_user_mapping)?.user
							.full_name || '- -',
				}}
				updateFeedback={(feedback: string) => {
					handleManagerReviewFeedback(feedback);
				}}
				isLoading={isManagerFeedbackLoading}
			/>
			<PMSManagerFeedbackForm
				onClose={() => {
					setIsFeedbackFormShown(false);
				}}
				open={isFeedbackFormShown}
				details={{
					appraisalName:
						head(userAppraisalDetails?.appraisalCycle)?.name || '- -',
					userName:
						head(userAppraisalDetails?.pms_pms_appraisal_user_mapping)?.user
							.full_name || '- -',
				}}
				updateFeedback={(feedback: string) => {
					handleManagerReviewFeedback(feedback);
				}}
				isLoading={isManagerFeedbackLoading}
				employee_self_feedback={
					head(userAppraisalDetails?.pms_pms_appraisal_user_mapping)?.user
						.appraisalFeedbacks[0]?.self_comment || ''
				}
			/>
			<ReviewGoalComment
				open={isGoalCommentModalOpen}
				onClose={() => {
					setIsGoalCommentModalOpen(false);
				}}
				goalMapping={goalMapping}
				onSuccess={() => {
					refetchUserAppraisalDetails();
				}}
			/>
		</div>
	);
}

export default ReporteePMSView;
