import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const modalFormStyle = makeStyles({
	container: {
		width: '442px',
		minHeight: '300px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	headerContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
		textTransform: 'uppercase',
	},
	closeIcon: {
		width: '20px',
		height: '20px',
		cursor: 'pointer',
	},
	label: {
		font: 'normal normal bold 10px/24px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '10px !important',
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingBottom: '30px',
	},
	floatingButtonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginTop: '32px',
	},
	saveButton: {
		minWidth: '126px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	delButton: {
		minWidth: '100px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
		marginLeft: '10px',
	},
	deleteButton: {
		width: '126px',
		height: '36px',
		background: '#EA4335 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#EA4335',
		},
	},
	cancelButton: {
		width: '126px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		marginRight: '10px',
		textTransform: 'none',
	},
	updateButton: {
		width: '126px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		marginRight: '10px',
		'&:hover': {
			background: '#4285F4',
		},
	},
	formContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		marginTop: '20px',
	},
	multipleInputContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	multipleInput: {
		width: '49%',
	},
	suggestionContainer: {
		zIndex: 4000,
	},
	disabledButton: {
		color: 'grey',
		backgroundColor: 'lightgrey',
		width: '126px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
	},
	customButtonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	infoContentContainer: {
		marginTop: '20px',
	},
	infoContent: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#5C5C5C',
	},
	eligibleContainer: {
		width: '100%',
		height: '48px',
		background: '#ECF1F4 0% 0% no-repeat padding-box',
		borderRadius: '4px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '30px',
		padding: '0px 10px 0px 10px',
	},
	radioButtonLabel: {
		font: 'normal normal bold 12px/37px Manrope',
		color: '#292929',
	},
	warningContent: {
		fontFamily: 'Manrope-bold',
		fontSize: '12px',
		color: 'red',
	},
	disabledStartButton: {
		color: 'grey',
		backgroundColor: 'lightgrey',
		width: '126px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
		marginRight: '10px',
	},
	dialogueContent: {
		padding: '0px 24px',
	},
	adonmentStyle: {
		marginBottom: '15px',
		width: '23px',
		height: '100%',
	},
	errorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '11px !important',
		color: '#EA4335',
		marginTop: '2px',
		marginBottom: '8px',
		marginLeft: '6px',
		height: '5px',
	},
	phoneNumberError: {
		fontFamily: 'Manrope-medium',
		fontSize: '10px !important',
		color: '#EA4335',
		marginTop: '-10px',
		marginBottom: '8px',
		marginLeft: '6px',
		height: '5px',
	},

	rejectButton: {
		width: '100px',
		height: '36px',
		background: '#ED3D3D 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#ED3D3D',
		},
	},
	approveButton: {
		width: '100px',
		height: '36px',
		background: '#05950a 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#05950a',
		},
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		// font: 'normal normal 12px/37px Manrope',
		font: 'normal normal bold 10px/24px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '10px !important',
	},
	description: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '200px',
		'&:hover': {
			cursor: 'pointer',
		},
	},

	updateStatus: {
		fontSize: '16px',
		fontFamily: 'Manrope-semibold',
		marginBottom: '10px',
		color: 'red',
	},

	errorContainer: {
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		padding: '10px',
		fontSize: '12px',
		fontFamily: 'Manrope',
		color: 'red',
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '200px',
		overflowY: 'scroll',
	},

	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	iconContainer: {
		width: '24px',
		height: '24px',
		marginTop: '6px',
	},
	infoIcon: {
		width: '12px',
		height: '12px',
	},

	editIcon: {
		width: '12px',
		height: '12px',
	},
});

export const ellipsisStyle = makeStyles({
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	customFieldEllipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		width: '65px',
	},
});

// global styles for list and grid view

export const globalStyles = makeStyles({
	gridList: {
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 19%)',
		maxWidth: 1220,
		margin: 'auto',
		gap: '16px',
		marginTop: '10px',
	},

	iconButton: {
		width: '36px',
		height: '36px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		marginRight: '10px',
		color: '#888888',
		background: 'white',
	},
	activeIconButton: {
		color: '#4285F4',
		border: '1px solid #4285F4',
	},
	filterButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		width: '36px',
		height: '36px',
		marginRight: '10px',
	},
	toolbarContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: '50px',
		marginBottom: '15px',
	},
	navigationTabContainer: {
		width: '420px',
		height: '36px',
		display: 'flex',
		marginTop: '10px',
	},
	navigationTabButtons: {
		fontFamily: 'Manrope-semibold',
		color: '#888888',
		fontSize: '12px',
		width: '50%',
		background: '#ECF1F4',
		textTransform: 'none',
		borderRadius: '0px',
		letterSpacing: '0px',
		'&:first-child': {
			borderRight: '1px solid #E0E0E0',
			borderRadius: '4px 0px 0px 4px !important',
		},
		'&:last-child': {
			borderRadius: '0px 4px 4px 0px',
		},
	},
	activeNavigationTabButton: {
		borderBottom: '2px solid #4285F4',
		color: '#4285F4',
	},
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '12px',
	},
	listStyle: {
		position: 'relative',
		marginTop: '-50px', // TODO Need to remove this negative margin value and fix it proper CSS (Because of react admin upgrade)
	},
	filterStyle: {
		position: 'absolute',
		bottom: '100px',
	},
	addButton: {
		textTransform: 'none',
		minWidth: '140px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#FFFFFF',
		padding: '10px 16px',
		'&:hover': {
			backgroundColor: '#4285F4',
		},
	},
	scrollableAuditContainer: {
		maxHeight: '492px',
		overflowX: 'hidden',
		overflowY: 'auto',
		paddingTop: '10px',
	},
	backButton: {
		width: '89px',
		height: '36px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/32px Manrope',
		color: '#4285F4',
		cursor: 'pointer',
		marginLeft: '85%',
		marginTop: '-35px',
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		width: '150px',
		textOverflow: 'ellipsis',
		fontSize: '12px',
	},
});
// Reusable style for currency in  opportunity add and edit form
export const currencyInputStyle = makeStyles({
	boxContainer: {
		display: 'flex',
		maxHeight: '50px',
		width: '100%',
	},
	dateInput: {
		marginTop: '8px',
		width: '202px',
	},
	selectCurrency: {
		width: '80px',
	},
	subHeading: {
		color: '#4285F4',
		font: 'normal normal bold 12px/20px Manrope',
		letterSpacing: '0px',
		fontSize: '12px !important',
		marginLeft: '8px',
	},
	detailsContainer: {
		width: '464px',
		height: '716px',
		borderRadius: '4px',
	},
	headerContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '20px',
		position: 'sticky',
		top: 0,
		zIndex: '222',
		background: 'white',
	},
	formContainer: {
		marginTop: 0,
	},
	heading: {
		font: 'normal normal bold 14px/20px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
		textTransform: 'uppercase',
		display: 'flex',
	},
	arrowIcon: {
		fontSize: '12px',
		marginLeft: '8px',
		marginTop: '3px',
	},
	multipleInputContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		height: '16px',
	},
});

// global style for grid card in grid view
export const gridCardGlobalStyle = makeStyles((theme) => ({
	container: {
		width: '190px',
		minHeight: '230px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		padding: '1em',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
		cursor: 'pointer',
		'&:hover': {
			boxShadow: '0px 36px 60px #0000000F',
		},
	},
	identity: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	titleContainer: {
		textAlign: 'center',
		marginTop: theme.spacing(1),
	},
	avatar: {
		width: ' 55px',
		height: '55px',
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		opacity: '1',
		alignItems: 'center',
		borderRadius: '50px',
		display: 'flex',
		justifyContent: 'center',
		marginTop: '25px',
	},
	subTitleContent: {
		fontSize: '12px',
		fontFamily: 'Manrope-semiBold',
		color: '#6F6F6F',
		opacity: 1,
	},
	secondaryInfo: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '20px',
	},
	secondaryInfoContent: {
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
	},
	additionalInfo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '8px',
	},
	additionalInfoContent: {
		fontSize: '12px',
		opacity: 1,
		marginTop: '20px',
		marginBottom: '15px',
	},
	gridList: {
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 18%)',
		maxWidth: 1220,
		gap: '18px',
		margin: '30px 0px 20px 20px',
	},

	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		width: '150px',
		textOverflow: 'ellipsis',
	},
}));

// global style for details page which contains left panel and tabs
export const detailsPageGlobalStyle = makeStyles((theme) => ({
	container: {
		display: 'flex',
	},
	leftPanel: {
		marginRight: '16px',
	},
	rightPanel: {
		width: '100%',
	},
	toolBar: {
		display: 'flex',
		justifyContent: 'space-between',
		height: '60px',
		alignItems: 'center',
	},
	backButton: {
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		font: 'normal normal bold 12px/32px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		cursor: 'pointer',
		textTransform: 'none',
	},
	detailsPageTitle: {
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		color: '#5C5C5C',
		cursor: 'pointer',
	},
	arrowIcon: {
		color: '#5C5C5C',
		padding: '0px 7px',
	},
}));
// global style for left card in details page
export const detailsLeftCardStyle = makeStyles((theme) => ({
	container: {
		width: '246px',
		height: '584px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatarContainer: {
		width: '246px',
		height: '91px',
		backgroundColor: '#4285F4',
		borderRadius: '4px 4px 0px 0px',
		opacity: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '60px',
	},
	avatarField: {
		width: '104px',
		height: '104px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		opacity: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '100px',
		marginTop: '85px',
		position: 'relative',
		left: '16px',
	},
	identityContainer: {
		width: '246px',
		minHeight: '80px',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '15px',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '36px',
		height: '36px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			background: '#4285F4',
		},
	},
	editIcon: {
		width: '20px',
		height: '20px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	editButtonContainer: {
		opacity: 1,
		width: '36px',
		height: '36px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '85px',
		position: 'relative',
		left: '33px',
	},
	nameField: {
		font: 'normal normal 800 16px/47px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '16px',
		lineHeight: 'inherit',
	},
	assetName: {
		font: 'normal normal 800 16px/47px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '16px',
		lineHeight: 'inherit',
		marginLeft: '25px',
		marginRight: '20px',
	},
	addtionalInfoContainer: {
		width: '85%',
		minHeight: '200px',
	},
	additionalContent: {
		display: 'flex',
		justifyContent: 'space-between',
		borderBottom: '1px solid #E0E0E0',
	},
	additionalInfoValue: {
		font: 'normal normal 600 12px/40px Manrope !important',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '12px !important',
		padding: '0 5px 0 10px',
		maxWidth: '120px',
	},
	additionalInfoLabel: {
		font: 'normal normal 600 10px/40px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
	},
	title: {
		font: 'normal normal 800 16px/47px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '16px',
		lineHeight: 'inherit',
	},
	phoneIcon: {
		width: '14px',
		paddingRight: '4px',
	},
	secondaryInfoField: {
		width: '100px',
		margin: '0 auto',
		font: 'normal normal 600 12px/20px Manrope !important',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '12px !important',
	},
	designationInfoField: {
		width: '180px',
		margin: '0 auto',
		font: 'normal normal 600 12px/20px Manrope !important',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '12px !important',
	},
	additionalInfoField: {
		font: 'normal normal medium Manrope',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
	},
	activeColor: {
		backgroundColor: '#34A853',
	},
	inactiveColor: {
		backgroundColor: '#5C5C5C',
	},
	infoBarValueContainer: {
		width: '67px',
		justifyContent: 'center',
		position: 'absolute',
		right: '0px',
	},
	infoBar: {
		width: '84px',
		height: '20px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '2px',
		opacity: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: '8px',
		position: 'relative',
	},
	infoField: {
		fontSize: '10px !important',
		font: 'normal normal bold 10px/13px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
	},
	billableIcon: {
		fontSize: 'small',
		color: '#5C5C5C',
		position: 'absolute',
		left: '6px',
	},
	infoBarContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '10px',
	},
	activeIcon: {
		width: '7px',
		height: '7px',
		borderRadius: '100px',
		position: 'absolute',
		left: '6px',
	},
}));
export const datagridStyle = makeStyles({
	listEditIconContainer: {
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
	},
	deleteIconButton: {
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	listDeleteIconContainer: {
		width: '24px',
		height: '24px',
	},
	listDeleteIconButton: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	deleteIcon: {
		width: '12px',
		height: '12px',
	},
});
// global style for details in the tab of details page
export const detailsPageTabDetailsStyle = makeStyles((theme) => ({
	label: {
		textAlign: 'left',
		font: 'normal normal bold 10px/25px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '10px !important',
		textTransform: 'capitalize',
	},
	value: {
		textAlign: 'left',
		font: 'normal normal medium Manrope',
		letterSpacing: '0px',
		color: ' #5C5C5C',
		opacity: 1,
		fontSize: '12px !important',
	},
	editIcon: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
		paddingTop: '3px',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	editContainer: {
		display: 'flex',
		cursor: 'pointer',
		width: '100px',
		height: '17px',
		fontFamily: 'Manrope-normal',
		color: '#4285F4',
		opacity: '1',
		marginBottom: '20px',
	},
	editButton: {
		width: '10px',
		height: '10px',
		marginTop: '6px',
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
}));

// global style for details skill tab of details page common for employee and consultant.
export const detailsPageSkillTabStyle = makeStyles((theme) => ({
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
	skillDataContainer: {
		maxWidth: '314px',
		width: '300px',
		height: '61px',
		background: '#F7F9FA',
		borderRadius: '4px',
		opacity: 1,
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		padding: '0px 20px 0px 20px',
		margin: '0px 10px 10px 0px',
		'&:hover': {
			boxShadow: '0px 1px 1px 1px lightgrey',
		},
	},
	skillName: {
		font: 'normal normal bold 12px/15px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '12px !important',
	},
	skillLevel: {
		font: 'normal normal bold 12px/44px Manrope',
		letterSpacing: '0px',
		color: '#888888',
		opacity: 1,
		fontSize: '12px !important',
		marginLeft: 'auto',
	},
	addNewSkill: {
		width: '300px',
		height: '64px',
		font: 'normal normal medium 12px/15px Manrope',
		background: '#F7F9FA 0% 0% no-repeat padding-box',
		borderRadius: '4px',
		opacity: ' 1',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '10px',
		'&:hover': {
			color: '#4285F4 !important',
		},
	},
	addSkillText: {
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: ' 1',
		marginLeft: '12px',
		fontSize: '12px',
		'&:hover': {
			color: '#4285F4',
		},
	},
	addSkillIcon: {
		fontFamily: 'Manrope-Light',
		width: '24px',
		height: '24px',
		color: '#5C5C5C',
		opacity: ' 1',
		'&:hover': {
			color: '#4285F4',
		},
	},
	textBox: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	skillContainer: {
		flexWrap: 'wrap',
		flexDirection: 'column',
		display: 'flex',
		minwidth: '400px',
		height: '424px',
		marginTop: '24px',
	},
}));

// global style for the insight view we can reuse this style for count card and chart component
export const insightViewStyle = makeStyles({
	container: {
		marginTop: '16px',
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gap: '15px',
	},
	largeContainer: {
		marginTop: '16px',
		display: 'grid',
		gap: '15px',
	},
	chartContainer: {
		'&:nth-child(odd)': {
			width: '40vw',
		},
		'&:nth-child(even)': {
			width: '40vw',
		},
	},
	headCountContainer: {
		height: '96px',
		background: '#FFFFFF',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		marginTop: '16px',
		display: 'flex',
		alignItems: 'center',
		padding: '0px 24px 0px 24px',
	},
	countContainer: {
		minWidth: '161px',
		height: '48px',
		background: '#F7F9FA',
		borderRadius: '4px',
		padding: '6px 12px 6px 12px',
		marginRight: '16px',
	},
	countLabel: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#5C5C5C',
	},
	countValue: {
		fontSize: '14px',
		fontFamily: 'Manrope-semibold',
		color: '#292929',
		display: 'flex',
		justifyContent: 'left',
	},
	reducedCountContainer: {
		height: '65px',
		background: '#FFFFFF',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		marginTop: '16px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	reducedValueContainer: {
		minWidth: '67px',
		height: '48px',
		background: '#F7F9FA',
		borderRadius: '4px',
		padding: '6px 12px 6px 12px',
		marginRight: '16px',
	},
	countContainerWithoutGrey: {
		minWidth: '161px',
		height: '50px',
		borderRadius: '4px',
		padding: '6px 12px 6px 12px',
		marginRight: '16px',
		marginLeft: '5px',
	},
});

// global style for drawer filter
export const drawerFilterStyle = makeStyles((theme) => ({
	drawerContainer: {
		width: '328px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		opacity: 1,
		padding: '50px',
	},
	drawerHeading: {
		fontSize: '14px',
		textAlign: 'left',
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		textTransform: 'uppercase',
	},
	HeaderSection: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	resetButton: {
		textDecoration: 'underline',
		font: 'normal normal bold 12px/23px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		opacity: 1,
		cursor: 'pointer',
	},
	applyFilterButton: {
		width: '130px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		opacity: 1,
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		fontSize: '12px',
		marginLeft: '10px',
		'&:hover': {
			color: '#FFFF',
			background: '#366CC6',
		},
	},
	cancelButton: {
		width: '130px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: ' #4285F4',
		opacity: 1,
		textTransform: 'none',
		fontSize: '12px',
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	header: {
		position: 'absolute',
		top: '30px',
		left: '30px',
		right: '30px',
		overflow: 'hidden',
	},
	content: {
		position: 'absolute',
		top: '60px',
		left: '30px',
		right: '0px',
		height: '500px',
		overflow: 'auto',
	},
	footer: {
		position: 'absolute',
		bottom: '40px',
		left: '30px',
	},
	container: {
		margin: '10px 20px 10px 0px',
	},
}));

export const detailPageDocumentStyle = makeStyles((theme) => ({
	paperBox: {
		border: '1px solid #E0E0E0',
		height: 172,
		width: 172,
		position: 'relative',
	},
	avatar: {
		top: '58px',
		backgroundColor: '#ECF1F4 !important',
		margin: 'auto',
	},
	avtharIcon: {
		color: '#000000',
		displa: 'flex',
		margin: 'auto',
	},
	paperText: {
		position: 'absolute',
		bottom: '25px',
		textAlign: 'center',
		color: '#292929',
		width: '100%',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: '0px 44px',
	},
	addDocBox: {
		cursor: 'pointer',
		height: 172,
		width: 172,
		display: 'flex',
		margin: 'auto',
		border: '2px dashed #E0E0E0',
		'&:hover': {
			border: '2px dashed #4285F4',
			backgroundColor: 'rgba(66, 133, 244, 0.1)',
		},
	},
	uploadButton: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		width: '100%',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	uploadTile: {
		color: '#4285F4',
		fontSize: 'inherit',
		marginTop: '10px',
	},
	docsContainer: {
		overflow: 'auto',
		alignContent: 'flex-start',
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
}));

export const detailPageAssestTabStyle = makeStyles(() => ({
	gridList: {
		display: 'grid',
		gridTemplateColumns: 'repeat(4, 19%)',
		width: '890px',
		marginTop: '10px',
		gap: '40px',
	},
	gridContainer: {
		height: '500px',
		overflowY: 'scroll',
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
}));

export const labelStyle = makeStyles(() => ({
	dropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-6px',
		marginBottom: '-1px',
	},
}));

export const DialogContent = withStyles(() => ({
	root: {
		width: '420px',
		maxHeight: '70vh',
		padding: '0px 10px 0px 0px',
	},
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export const gridViewStyle = makeStyles({});

export const AutocompleteSearchStyles = makeStyles({
	calenderIcon: {
		width: '16px',
		height: '16px',
		marginRight: '4px',
		color: '#838383',
	},
	timesheetFormActionContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	options: {
		fontSize: '12px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
	},
	groupLabel: {
		fontSize: '12px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
	},
	inputRoot: {
		width: '100%',
		padding: '0px 56px 0px 4px !important',
		fontSize: '12px',
		height: '36px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		background: '#FFFFFF',
	},
	inputRootWrap: {
		fontSize: '12px',
		height: '36px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		background: '#FFFFFF',
	},
	noOptions: {
		fontSize: '12px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
	},
	focused: {
		border: 'none',
		borderBottom: 'none',
	},
	notchedOutline: {
		border: 'none',
		borderBottom: 'none',
	},
	input: {
		padding: '4.5px 4px !important',
	},
	endAdornment: {
		marginRight: '6px',
	},
	customFieldContainer: {
		paddingBottom: '12px',
	},
	inputAdronment: {
		height: '1em',
		maHeight: '2em',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		marginLeft: '7px',
		width: '18px',
		display: 'block',
	},
	inputAdronmentPositionStart: {
		marginRight: '0px',
	},
	searchIconPositionStart: {
		marginTop: '13px',
	},
});

export const BreadcrumStyles = makeStyles({
	toolBar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		color: '#5C5C5C',
		cursor: 'pointer',
		'&:hover': {
			color: '#4285F4',
			textDecoration: 'underline',
		},
	},
	details: {
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		color: '#4285F4',
		cursor: 'pointer',
		maxWidth: '120px',
	},
	arrowIcon: {
		color: '#5C5C5C',
		padding: '0px 7px',
	},
	backButton: {
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		font: 'normal normal bold 12px/32px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		cursor: 'pointer',
	},
	navigate: {
		width: '40px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		height: '36px',
		borderRadius: '4px',
		marginRight: '10px',
		color: '#4285F4',
		cursor: 'pointer',
		opacity: 1,
		float: 'left',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	projectCloneButton: {
		height: '36px',
		font: 'normal normal bold 12px/32px Manrope',
		backgroundColor: '#4285F4',
		letterSpacing: '0px',
		color: '#ffffff',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		marginRight: '10px',
		cursor: 'pointer',
		opacity: 1,
		float: 'left',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '2px',
	},
});

export const exportButtonStyles = makeStyles({
	exportButton: {
		minWidth: '60px',
		height: '34px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
});

export const autocompleteStyle = makeStyles({
	dropdownValue: {
		marginTop: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
});

export const InsightReportTableGlobalStyles = makeStyles({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: '17px',
		marginTop: '10px',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	tableContainer: {
		maxHeight: '400px',
		overflowY: 'auto',
	},
	noData: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	noDataLabel: {
		fontSize: '12px',
		fontFamily: 'Manrope-semiBold',
	},
	dataCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '75px',
	},
	dateCell: {
		display: 'block',
		fontSize: '10px',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		fontFamily: 'Manrope-semibold',
	},
	weekEndCell: {
		color: '#e63a2e',
	},
	timeSheetWithDate: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		display: 'flex',
		alignItems: 'center',
		justifyContent: ' center',
	},
	timeSheetWithDateWithLeave: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#e63a2e',
		display: 'flex',
		alignItems: 'center',
		justifyContent: ' center',
	},
	timeSheetWithoutDate: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: ' center',
	},
	headerCell: {
		minWidth: '120px',
		padding: '12px',
	},
	circle: {
		height: '12px',
		width: '12px',
		backgroundColor: '#bbb',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '9px',
		fontFamily: 'Manrope-bold',
		visibility: 'hidden',
	},
	holidayCircle: {
		height: '12px',
		width: '12px',
		backgroundColor: '#bbb',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '9px',
		fontFamily: 'Manrope-bold',
	},
	monthCell: {
		width: '10px',
	},
	autoCompleteInputContainer: {
		minWidth: '200px',
		marginRight: '8px',
	},
	label: {
		font: 'normal normal bold 10px/24px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '10px !important',
	},
});

export const dropDownEllipsisStyle = makeStyles({
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		fontSize: '12px',
	},
});
