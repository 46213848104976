import {
	makeStyles,
	createTheme,
	withStyles,
	Tooltip,
} from '@material-ui/core';

export const Style = makeStyles(() => ({
	line: {
		borderBottom: '1px solid ',
	},
	backButton: {
		width: '89px',
		height: '36px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/32px Manrope',
		color: '#4285F4',
		cursor: 'pointer',
		marginRight: '22px',
	},
	summaryContainer: {
		width: '475px',
		// height: '586px',
		maxHeight: '586px',
		overflow: 'auto',
		background: '#FFFFFF',
	},
	additionalInfoLabelLeave: {
		font: 'normal normal 600 10px/40px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
		maxWidth: '60px',
	},
	leaveCount: {
		font: 'normal normal 600 10px/40px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
	},
	leaveApproverValue: {
		font: 'normal normal 600 12px/40px Manrope !important',
		color: '#292929',
		opacity: 1,
		fontSize: '12px !important',
		maxWidth: '100px',
	},
	attendanceContainer: {
		width: '704px',
		height: '120px',
		background: '#FFFFFF',
		marginLeft: '16px',
	},
	summaryTitle: {
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-semibold',
		marginLeft: '8px',
	},
	addtionalInfoContainer: {
		width: '85%',
		minHeight: '200px',
		marginLeft: '21px',
	},
	additionalContent: {
		display: 'flex',
		justifyContent: 'space-between',
		borderBottom: '1px solid #E0E0E0',
	},
	additionalInfoValue: {
		font: 'normal normal 600 12px/40px Manrope !important',
		color: '#292929',
		opacity: 1,
		fontSize: '12px !important',
	},
	additionalInfoLabel: {
		font: 'normal normal 600 10px/40px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
	},
	addLeaveButton: {
		textTransform: 'none',
		minWidth: '140px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		fontSize: '12px',
		fontFamily: 'Manrope-bold',
		color: '#FFFFFF',
		'&:hover': {
			backgroundColor: '#4285F4',
		},
	},
	attendanceDetails: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: '20px',
		marginRight: '24px',
	},
	addCheckButtonContainer: {
		background: '#F7F9FA',
		width: '300px',
		height: '54px',
		padding: '7px',
		fontSize: '12px',
		fontFamily: 'Manrope-normal',
		display: 'flex',
	},
	addCheckButton: {
		background: '#4285F4',
		height: '40px',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disableButton: {
		background: '#dcdcdc',
		height: '40px',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		'&:hover': {
			background: '#dcdcdc',
		},
	},
	addCheckoutButton: {
		color: '#BEC9D0',
		textTransform: 'capitalize',
	},

	calenderContainer: {
		marginTop: '10px',
		background: '#FFFFFF',
		width: '704px',
		height: '456px',
		marginLeft: '20px',
	},
	calenderTitle: {
		color: '#292929',
		font: 'normal normal bold 12px/15px Manrope',
		marginLeft: '29px',
		padding: '20px',
	},
	workingDayContainer: {
		background: '#ECF1F4',
		borderRadius: '4px',
		width: '258px',
		height: '370px',
	},
	workingAttendanceContainer: {
		background: '#FFFFFF',
		borderRadius: '2px',
		margin: '6px',
		height: '358px',
	},
	workingDayTitle: {
		color: '#4285F4',
		fontFamily: 'Manrope-bold',
		fontSize: '10px',
	},
	dayTitle: {
		color: '#888888',
		fontSize: '8px',
		fontFamily: 'Manrope-bold',
		marginLeft: '30px',
	},
	attendanceNotMarked: {
		color: '#888888',
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
		marginTop: '18px',
		marginLeft: '30px',
	},
	hourContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		alignContent: 'center',
		marginTop: '16px',
	},
	hoursWorked: {
		color: '#888888',
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
	},
	hoursWorkedRight: {
		color: '#888888',
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
		display: 'flex',
	},
	clockIcon: {
		marginTop: '4px',
		width: '18px',
		height: '18px',
	},
	datePicker: {
		marginLeft: '26px',
		marginRight: '35px',
		marginTop: '11px',
	},
	dateInput: {
		color: '#5C5C5C',
		height: '14px',
		width: '144px',
	},
	calenderListWeekend: {
		height: '16px',
		width: '16px',
		background: '#888888',
	},
	calenderListAbsent: {
		height: '16px',
		width: '16px',
		background: '#EA4335',
	},
	calenderListHoliday: {
		height: '16px',
		width: '16px',
		background: '#FFDC7F',
	},
	calenderListPresent: {
		height: '16px',
		width: '16px',
		background: '#99D3A8',
	},
	calenderListLeave: {
		height: '16px',
		width: '16px',
		background: '#f98203',
	},
	calenderListTitle: {
		color: '#5C5C5C',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		marginLeft: '10px',
		marginRight: '18px',
	},
	calender: {
		color: '#888888',
		background: '#ECF1F4',
		borderRadius: '8px',
	},
	absent: {
		background: '#EA4335',
		borderRadius: '8px',
	},
	holiday: {
		background: '#FFDC7F',
		borderRadius: '8px',
	},
	employeeLeave: {
		background: '#f98203',
		borderRadius: '8px',
	},
	present: {
		background: '#34A85380',
		borderRadius: '8px',
	},
	weekend: {
		color: 'rgba(0, 0, 0, 0.87)',
		borderRadius: '8px',
		backgroundColor: '#888888',
		opacity: 0.5,
	},
	calenderDays: {
		background: '##FFFFFF',
		borderRadius: '6px',
		color: '#FFFFFF',
	},
	leaveDays: {
		fontSize: '9px',
		fontWeight: 'bold',

		textAlign: 'center',
		fontFamily: 'Manrope-bold',
		width: '12px',
		height: '12px',
		marginRight: '-30px',
		marginBottom: '-18px',
		display: 'inline-block',
		backgroundColor: '#bbb',
		borderRadius: '50%',
		verticalAlign: 'text-bottom',
		justifyContent: 'center',
		cursor: 'pointer',
		zIndex: '1000',
	},
	leaveAndHoliday: {
		marginRight: '-10px',
		marginBottom: '7px',
	},

	leaveHolidayBox: {
		marginLeft: '20px',
		marginBottom: '3px',
	},

	workingAttendanceInnerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		height: '20px',
		paddingTop: '20px',
		paddingBottom: '8px',
	},
	dateHighlight: {
		backgroundColor: '#ECF1F4',
		borderRadius: '8px',
		color: '#292929',
	},
	checkedin: {
		display: 'flex',
		fontSize: '12px',
		fontFamily: 'Manrope-bold',
		textTransform: 'capitalize',
	},
	checkoutOut: {
		background: '#FFDC7F',
		color: '#888888',
		textTransform: 'capitalize',
		'&:hover': {
			background: '#FFDC7F',
			color: '#888888',
		},
	},
	todaysDate: {
		color: '#292929',
		fontSize: '16px',
		fontFamily: 'Manrope-bold',
	},
	todaysDay: {
		color: '#5C5C5C',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		marginTop: '3px',
	},
	container: {
		flexGrow: 1,
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
		marginTop: '10px',
		marginRight: '23px',
	},
	navigationTabButtons: {
		fontFamily: 'Manrope-semibold',
		color: '#888888',
		fontSize: '12px',
		width: '50%',
		textTransform: 'none',
		borderRadius: '0px',
		letterSpacing: '0px',
		borderRight: '1px solid #E0E0E0',
		background: '#ECF1F4',
		'&:first-child': {
			borderRight: '1px solid #E0E0E0',
			borderRadius: '4px 0px 0px 4px !important',
		},
		'&:last-child': {
			borderRadius: '0px 4px 4px 0px',
		},
	},
	AttendanceContainer: {
		background: '#FFFFFF',
		borderRadius: '2px',
		marginTop: '81px',
		marginLeft: '24px',
		marginRight: '24px',
		height: '140px',
	},
	noData: {
		fontFamily: 'Manrope-medium',
		letterSpacing: '0px',
		fontSize: '12px !important',
		marginTop: '20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	rejectButton: {
		background: '#ED3D3D',
		fontFamily: 'Manrope-bold',
		color: '#FFFFFF',
		fontSize: '12px',
		textTransform: 'none',
		'&:hover': {
			background: '#ED3D3D',
		},
	},
	reason: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '100px',
	},
	leaveTYpe: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '80px',
	},
	leavesContainer: {
		maxHeight: '70vh',
		overflowY: 'auto',
	},
	avatarContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '10px',
	},
	avatar: {
		width: '32px',
		height: '32px',
		fontSize: '14px',
		fontFamily: 'Manrope-medium',
	},
	employeeName: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '12px',
		color: '#343434',
		marginLeft: '12px',
	},
	dataActionContainer: {
		width: '10px',
	},
	checkBox: {
		padding: '0px 0px 0px 22px',
	},
	calendarIcon: {
		width: '16px',
		height: '16px',
		color: '#888888',
		marginRight: '12px',
	},
	dateContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	statusContainer: {
		fontFamily: 'Manrope-semibold',
		fontSize: '10px',
		borderRadius: '10px',
		padding: '4px 8px 4px 8px',
		width: 'fit-content',
	},
	submittedStatus: {
		backgroundColor: '#FFF9E2',
		color: '#EF9F20',
	},
	approvedStatus: {
		backgroundColor: '#C9F4CB',
		color: '#118115',
	},
	rejectedStatus: {
		backgroundColor: '#FFF3F2',
		color: '#EA4335',
	},
	approvedIcon: {
		width: '22px',
		height: '22px',
		color: '#3FA543',
		margin: '5px 0px 0px 22px',
	},
	customTooltip: {
		fontFamily: 'Manrope-Medium',
		fontSize: '12px',
		backgroundColor: '#292929',
		border: '1px solid #F7F9FA',
		color: '#FFFFFF',
		boxShadow: '0px 36px 60px #0000000F',
	},
}));

export const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '70%',
				height: '20px',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingLeft: '0px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});

export const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);
