import React, { useState, useEffect, useContext } from 'react';
import { SelectInput } from 'react-admin';
import { range } from 'lodash';
import { ThemeProvider } from '@material-ui/core/styles';
import { dropDownEllipsisStyle, modalFormStyle } from '../../Layout/styles';
import {
	modalFormTheme,
	customAutoCompleteSearchBarTheme,
} from '../../Layout/Theme.component';
import { Form } from 'react-final-form';
import {
	Box,
	Button,
	CircularProgress,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { UserProfileContext } from '../../App';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
	useGetAttendanceYearQuery,
	useGetMyTeamViewInAttendanceQuery,
} from '../../generated/graphql';
import { monthOptions } from '../constant';
import dayjs from 'dayjs';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { excelDownload } from '../../Utils/string.util';
import { exportMyTeamLeavePolicyBalance } from '../../Api/restApi.config';
import WarningModal from '../../SharedComponents/WarningModal.component';

interface Year {
	id: number;
	name: number;
}

interface Props {
	setSelectedDate: (startDate: string, endDate: string) => void;
	setUserId: (userId: string) => void;
	userId: string;
	setSelectedAttendanceMonth: (month: string) => void;
	selectedAttendanceMonth: string;
	setEmployeeId: (id: string) => void;
}

const AttendanceMyTeamSearch = (props: Props) => {
	const {
		setUserId,
		setSelectedDate,
		userId,
		setSelectedAttendanceMonth,
		selectedAttendanceMonth,
		setEmployeeId,
	} = props;
	const [attendanceYear, setAttendanceYear] = useState<Year[]>([]);
	const [selectedAttendanceYear, setSelectedAttendanceYear] = useState(
		dayjs().year()
	);
	const { id: Id } = useContext<any>(UserProfileContext);

	const [monthsOption, setMonthsOption] = useState(monthOptions);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const classes = modalFormStyle();
	const ellipsis = dropDownEllipsisStyle();

	const { data: myTeam } = useGetMyTeamViewInAttendanceQuery({
		variables: {
			userId: Id,
		},
		fetchPolicy: 'network-only',
	});

	const { data: attendanceYears } = useGetAttendanceYearQuery();

	useEffect(() => {
		if (dayjs().year() === selectedAttendanceYear) {
			const monthOption = range(dayjs().month() + 1).map((value: number) => {
				return {
					id: value,
					name: dayjs().month(value).format('MMM'),
				};
			});
			setMonthsOption(monthOption);
		} else {
			setMonthsOption(monthOptions);
		}
	}, [selectedAttendanceYear]);

	useEffect(() => {
		if (!attendanceYears || !attendanceYears?.attendance_aggregate?.aggregate) {
			return;
		}
		const attendanceYear = attendanceYears?.attendance_aggregate?.aggregate;
		const maxYear = dayjs(attendanceYear?.max?.date).year() + 1;
		const minYear = dayjs(attendanceYear?.min?.date).year();
		const yearDropDownOptions = range(minYear, maxYear).map((value: number) => {
			return {
				id: value,
				name: value,
			};
		});
		setAttendanceYear(yearDropDownOptions);
	}, [attendanceYears]);

	const onSearchChange = (event: any, newValue: any) => {
		setEmployeeId('');
		if (newValue === null) {
			setUserId('');
			setEmployeeId('');
		}
		if (newValue?.user_type === 'employee') {
			setEmployeeId(newValue?.employee?.id);
		}
		setUserId(newValue?.id);
	};

	const calculateWithYear = (value: string) => {
		const startDate = `${value}-${selectedAttendanceMonth}-01`;
		const totalDays = dayjs(startDate).daysInMonth();
		const endDate = `${value}-${selectedAttendanceMonth}-${totalDays}`;
		setSelectedDate(startDate, endDate);
	};

	const calculateWithMonth = (value: string) => {
		const startDate = `${selectedAttendanceYear}-${value}-01`;
		const totalDays = dayjs(startDate).daysInMonth();
		const endDate = `${selectedAttendanceYear}-${value}-${totalDays}`;
		setSelectedDate(startDate, endDate);
	};
	const exportMyTeamLeave = async (file: any) => {
		if (!file) {
			return;
		}
		setLoading(true);
		exportMyTeamLeavePolicyBalance()
			.then((res) => {
				if (res?.data?.error) {
					console.log(res.data.error);
					setLoading(false);
					return;
				}

				excelDownload(res.data, 'My Team Leave Balance');
				setLoading(false);
				return res.data;
			})
			.catch((err) => {
				console.log(err?.response?.message);
				setErrorMessage(err?.response?.data?.message);
				setWarningModal(true);
				setLoading(false);
			});
	};

	return (
		<>
			<Box display='flex' alignItems='center' padding='5px 14px 0px 20px'>
				<Box marginRight='15px' marginTop='-16px'>
					<Box className={classes.label}>Choose employee or consultant</Box>
					<Autocomplete
						onChange={onSearchChange}
						options={myTeam?.user || []}
						style={{ width: 250 }}
						renderOption={(option: any) => (
							<Tooltip title={`${option?.full_name}`} placement='right'>
								<Typography className={`${ellipsis.ellipsis}`}>
									{`${option?.full_name}`}
								</Typography>
							</Tooltip>
						)}
						getOptionLabel={(option: any) => `${option?.full_name}`}
						renderInput={(params) => (
							<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
								<TextField
									{...params}
									label=''
									placeholder='Search employee or consultant'
								/>
							</ThemeProvider>
						)}
					/>
				</Box>
				<Box>
					<Box>
						<Form onSubmit={() => {}}>
							{(pristine) => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Box className={classes.label}>Choose Year</Box>
												<SelectInput
													source='year'
													label=''
													name='year'
													choices={attendanceYear}
													onChange={(event: any) => {
														setSelectedAttendanceYear(event?.target?.value);
														calculateWithYear(event?.target?.value);
													}}
													defaultValue={selectedAttendanceYear}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Box className={classes.label}>Choose Month</Box>
												<SelectInput
													placeholder='Choose month'
													source='month'
													label=''
													choices={monthsOption}
													name='month'
													disabled={!userId}
													onChange={(event: any) => {
														const month = dayjs()
															.month(event?.target?.value)
															.format('MM');
														setSelectedAttendanceMonth(month);
														calculateWithMonth(month);
													}}
												/>
											</Box>
										</Box>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
				</Box>

				<Box display={'flex'} marginLeft={'auto'}>
					<Button
						endIcon={<ArrowUpwardIcon />}
						className={loading ? classes?.disabledButton : classes.exportButton}
						disabled={loading}
						onClick={exportMyTeamLeave}
						startIcon={
							loading && (
								<CircularProgress
									style={{
										height: '20px',
										width: '20px',
										color: '#FFFFFF',
									}}
								/>
							)
						}
					>
						Export
					</Button>
				</Box>
			</Box>
			<WarningModal
				open={warningModal}
				onClose={() => {
					setWarningModal(false);
				}}
				warningMessage={`${errorMessage}`}
			/>
		</>
	);
};

export default AttendanceMyTeamSearch;
