import React, { useContext, useEffect, useState } from 'react';
import { FunctionField, ReferenceField, TextField } from 'react-admin';

import { getExperience } from '../../../Utils/date-time.util';
import DetailContainer from '../../../SharedComponents/Employee/DetailContainer.component';
import FieldContainer from '../../../SharedComponents/Employee/FieldContainer.componet';
import { useQuery as useApolloQuery } from '@apollo/client';
import { GET_ORGANISATION_BASE_CURRENCY } from './gqlQueries';
import head from 'lodash/head';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Tooltip } from '@material-ui/core';
import editicon from '../../../assets/icons/edit-icon.svg';
import EditEmployeeDetails from './EditEmployeeDetails.component';
import { UserProfileContext } from '../../../App';
import dayjs from 'dayjs';
import FieldRenderer from '../../../SharedComponents/FieldRenderer.component';

const useStyles = makeStyles((theme) => ({
	label: {
		textAlign: 'left',
		font: 'normal normal bold 10px/25px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '10px !important',
		textTransform: 'capitalize',
		marginRight: '15px',
	},
	value: {
		textAlign: 'left',
		font: 'normal normal medium Manrope',
		letterSpacing: '0px',
		color: ' #5C5C5C',
		opacity: 1,
		fontSize: '12px !important',
		marginRight: '15px',
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	editIcon: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
		paddingTop: '3px',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	editContainer: {
		display: 'flex',
		cursor: 'pointer',
		width: '100px',
		height: '17px',
		fontFamily: 'Manrope-normal',
		color: '#4285F4',
		opacity: '1',
		marginBottom: '17px',
	},
	editButton: {
		width: '10px',
		height: '10px',
		marginTop: '6px',
	},
}));

const EmployeePersonalDetails = (props) => {
	const {
		orgId: organisationId,
		permissions: permissionList,
		dateFormat,
	} = useContext(UserProfileContext);
	const [employeeDetailModalOpen, setEmployeeDetailsModalOpen] =
		useState(false);
	const [employeeDetails, setEmployeeDetails] = useState(null);
	const classes = useStyles();
	const { record: employee, refetch: refetchEmployee } = props;
	const { data: organisation } = useApolloQuery(
		GET_ORGANISATION_BASE_CURRENCY,
		{
			variables: {
				organisationId: organisationId,
			},
		}
	);

	useEffect(() => {
		setEmployeeDetails({
			personal_email: employee?.personal_email,
			highest_academic_degree: employee?.highest_academic_degree,
			join_date: employee?.join_date,
			employee_joining_source_id: employee?.employee_joining_source_id,
			aadhaar: employee?.aadhaar,
			blood_group: employee?.blood_group,
			gender: employee?.gender,
			pan: employee?.pan,
			marital_status: employee?.marital_status,
			emp_billing_cost: employee?.emp_billing_cost,
			emp_cost: employee?.emp_cost,
			address: employee?.address,
			id: employee?.id,
			years: employee?.prior_experience
				? employee?.prior_experience.slice(0, 2)
				: '00',
			months: employee?.prior_experience
				? employee?.prior_experience.slice(3, 5)
				: '00',
			currency_id: head(organisation?.organization)?.currency_id,
			org_shift_id: employee?.org_shift_id,
			last_date: employee?.last_date,
			leave_group_id: employee?.org_leave_group?.id,
			probation_start_date: employee?.probation_start_date,
			probation_end_date: employee?.probation_end_date,
			employeeStatus: employee?.employee_status?.label,
			location_id: employee?.location_id,
			dob: employee?.dob,
			institution_name: employee?.institution_name,
			employeeActive: employee?.employee_status?.value,
			custom_field_1: employee?.custom_field_1,
			custom_field_2: employee?.custom_field_2,
			custom_field_3: employee?.custom_field_3,
			custom_field_4: employee?.custom_field_4,
			custom_field_5: employee?.custom_field_5,
			custom_field_6: employee?.custom_field_6,
			custom_field_7: employee?.custom_field_7,
			custom_field_8: employee?.custom_field_8,
			custom_field_9: employee?.custom_field_9,
			wedding_date: employee?.wedding_date,
			emergency_contact_number: employee?.emergency_contact_number,
			emergency_contact_name: employee?.emergency_contact_name,
		});
	}, [employee, organisation]);
	return (
		<>
			{permissionList?.employee?.update_permissions ? (
				<Box
					className={classes.editContainer}
					onClick={() => setEmployeeDetailsModalOpen(true)}
				>
					<img className={classes.editButton} src={editicon} alt='editicon' />
					<Box className={classes.editIcon}>Edit Details</Box>
				</Box>
			) : null}
			<DetailContainer>
				<FieldContainer>
					<Typography className={classes.label}>Personal Email</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) => {
							return (
								<Tooltip
									title={`${employee?.personal_email || '- -'}`}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										`{employee?.personal_email || '- -'}`
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Address</Typography>
					<FunctionField
						record={employee}
						className={classes.value}
						render={(employee) => {
							return (
								<Tooltip
									title={
										employee?.address?.houseName &&
										employee?.address?.streetName
											? `${employee?.address?.houseName || ''}, ${
													employee?.address?.streetName || ''
											  }`
											: `${employee?.address?.houseName || ''}  ${
													employee?.address?.streetName || ''
											  }`
									}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{employee?.address?.houseName &&
										employee?.address?.streetName
											? `${employee?.address?.houseName || ''}, ${
													employee?.address?.streetName || ''
											  }`
											: `${employee?.address?.houseName || ''}  ${
													employee?.address?.streetName || ''
											  }`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					<FunctionField
						record={employee}
						className={classes.value}
						render={(employee) => {
							return (
								<Tooltip
									title={
										employee?.address?.houseName &&
										employee?.address?.streetName
											? `${employee?.address?.city || ''}, ${
													employee?.address?.postalCode || ''
											  }`
											: `${employee?.address?.city || ''}  ${
													employee?.address?.postalCode || ''
											  }`
									}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{employee?.address?.houseName &&
										employee?.address?.streetName
											? `${employee?.address?.city || ''}, ${
													employee?.address?.postalCode || ''
											  }`
											: `${employee?.address?.city || ''}  ${
													employee?.address?.postalCode || ''
											  }`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					<FunctionField
						record={employee}
						className={classes.value}
						render={(employee) => {
							return (
								<Tooltip
									title={`${employee?.address?.state || ''}`}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{`${employee?.address?.state || ''}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					<ReferenceField
						record={employee}
						link={false}
						reference='geo_list'
						source='address.country'
						label=''
					>
						<TextField className={classes.value} source='name' />
					</ReferenceField>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Prior Experience</Typography>
					{employee?.prior_experience ? (
						<FunctionField
							className={classes.value}
							record={employee}
							render={(employee) => {
								const employeeExperience = getExperience(employee);
								return (
									<Tooltip
										title={employeeExperience?.prevExp || '--'}
										placement='right'
									>
										<Typography
											className={`${classes.value} ${classes.ellipsis}`}
										>
											{employeeExperience?.prevExp || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
					) : (
						'- -'
					)}
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Current Experience</Typography>
					{employee?.join_date ? (
						<FunctionField
							className={classes.value}
							record={employee}
							render={(employee) => {
								const employeeExperience = getExperience(employee);
								return (
									<Tooltip
										title={employeeExperience?.psplExp || '--'}
										placement='right'
									>
										<Typography
											className={`${classes.value} ${classes.ellipsis}`}
										>
											{employeeExperience?.psplExp || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
					) : (
						'- -'
					)}
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Highest Degree</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) => {
							return (
								<Tooltip
									title={`${employee?.highest_academic_degree || '- -'}`}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{`${employee?.highest_academic_degree || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Institution</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) => {
							return (
								<Tooltip
									title={`${employee?.institution_name || '- -'}`}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{`${employee?.institution_name || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Joining Date</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) => {
							return (
								<Tooltip
									title={`${
										employee?.join_date
											? dayjs(employee?.join_date).format(dateFormat)
											: '- -'
									}`}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{`${
											employee?.join_date
												? dayjs(employee?.join_date).format(dateFormat)
												: '- -'
										}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Joining Source</Typography>
					{employee?.employee_joining_source_id ? (
						<ReferenceField
							link={false}
							record={employee}
							source='employee_joining_source_id'
							reference='emp_joining_source'
						>
							<TextField source='label' className={classes.value} />
						</ReferenceField>
					) : (
						<Typography className={classes.value}>- -</Typography>
					)}
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Blood Group</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) => {
							return (
								<Tooltip
									title={`${employee?.blood_group || '- -'}`}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{`${employee?.blood_group || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Marital Status</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) => {
							return (
								<Tooltip
									title={`${
										employee?.marital_status ? 'Married' : 'Unmarried'
									}`}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{`${employee?.marital_status ? 'Married' : 'Unmarried'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Wedding Date</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) =>
							`${
								employee?.marital_status
									? employee?.wedding_date
										? dayjs(employee?.wedding_date).format(dateFormat)
										: '- -'
									: '- -'
							}`
						}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>
						Emergency Contact Name
					</Typography>
					<Typography className={classes.value}>
						{employee?.emergency_contact_name
							? employee?.emergency_contact_name
							: '- -'}
					</Typography>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>
						Emergency Contact Number
					</Typography>
					<Typography className={classes.value}>
						{employee?.emergency_contact_number
							? employee?.emergency_contact_number
							: '- -'}
					</Typography>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>
						Employee Billing Rate
					</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) =>
							employee?.emp_billing_cost ? (
								<>
									<ReferenceField
										link={false}
										source='currency_id'
										reference='master_currencies'
										record={head(organisation?.organization)}
									>
										<TextField className={classes.value} source='symbol' />
									</ReferenceField>
									{(employee?.emp_billing_cost).toFixed(1) || 0}
								</>
							) : (
								'- -'
							)
						}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Employee Cost</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) =>
							employee?.emp_cost ? (
								<>
									<ReferenceField
										link={false}
										source='currency_id'
										reference='master_currencies'
										record={head(organisation?.organization)}
									>
										<TextField className={classes.value} source='symbol' />
									</ReferenceField>
									{(employee?.emp_cost).toFixed(1) || 0}
								</>
							) : (
								'- -'
							)
						}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Shift</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) =>
							employee?.org_shift_id ? (
								<>
									<ReferenceField
										link={false}
										source='org_shift_id'
										reference='org_shift'
										record={employee}
									>
										<TextField className={classes.value} source='name' />
									</ReferenceField>
								</>
							) : (
								'- -'
							)
						}
					/>
				</FieldContainer>
				{(employee?.employee_status?.value === 'inactive' ||
					employee?.employee_status?.label === 'Notice') && (
					<FieldContainer>
						<Typography className={classes.label}>Last Working Date</Typography>
						<FunctionField
							className={classes.value}
							record={employee}
							render={(employee) => {
								return (
									<Tooltip
										title={
											employee?.last_date
												? dayjs(employee?.last_date).format(dateFormat)
												: '- -'
										}
										placement='right'
									>
										<Typography
											className={`${classes.value} ${classes.ellipsis}`}
										>
											{employee?.last_date
												? dayjs(employee?.last_date).format(dateFormat)
												: '- -'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
					</FieldContainer>
				)}
				<FieldContainer>
					<Typography className={classes.label}>Leave policy</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) => {
							return (
								<Tooltip
									title={
										employee?.org_leave_group?.label
											? employee?.org_leave_group?.label
											: '- -'
									}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{employee?.org_leave_group?.label
											? employee?.org_leave_group?.label
											: '- -'}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>
						Probation Start Date
					</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) => {
							return (
								<Tooltip
									title={
										employee?.probation_start_date
											? dayjs(employee?.probation_start_date).format(dateFormat)
											: '- -'
									}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{employee?.probation_start_date
											? dayjs(employee?.probation_start_date).format(dateFormat)
											: '- -'}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Probation End Date</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) => {
							return (
								<Tooltip
									title={
										employee?.probation_end_date
											? dayjs(employee?.probation_end_date).format(dateFormat)
											: '- -'
									}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{employee?.probation_end_date
											? dayjs(employee?.probation_end_date).format(dateFormat)
											: '- -'}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Gender</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) => {
							return (
								<Tooltip title={employee?.gender || '- -'} placement='right'>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{employee?.gender || '- -'}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Date of Birth</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) => {
							return (
								<Tooltip
									title={
										employee?.dob
											? dayjs(employee?.dob).format(dateFormat)
											: '- -'
									}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{employee?.dob
											? dayjs(employee?.dob).format(dateFormat)
											: '- -'}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Location</Typography>
					<FunctionField
						className={classes.value}
						record={employee}
						render={(employee) =>
							employee?.location_id ? (
								<>
									<ReferenceField
										link={false}
										source='location_id'
										reference='org_location'
										record={employee}
									>
										<TextField className={classes.value} source='name' />
									</ReferenceField>
								</>
							) : (
								'- -'
							)
						}
					/>
				</FieldContainer>
				{/* CUSTOM FIELDS */}
				{employee?.employeeCustomFields?.custom_field?.length > 0 &&
					employee?.employeeCustomFields?.custom_field.map(
						(customField, index) => {
							return (
								<FieldContainer key={index}>
									<FieldRenderer
										tooltip={
											customField?.type === 'date' &&
											employee[`custom_field_${customField?.index}`]
												? dayjs(
														employee[`custom_field_${customField.index}`]
												  ).format(dateFormat) || '- -'
												: employee[`custom_field_${customField.index}`] || '- -'
										}
										label={customField.label}
										value={
											customField?.type === 'date' &&
											employee[`custom_field_${customField?.index}`]
												? dayjs(
														employee[`custom_field_${customField.index}`]
												  ).format(dateFormat) || '- -'
												: employee[`custom_field_${customField.index}`] || '- -'
										}
									/>
								</FieldContainer>
							);
						}
					)}
			</DetailContainer>
			<EditEmployeeDetails
				open={employeeDetailModalOpen}
				onClose={() => setEmployeeDetailsModalOpen(false)}
				record={employeeDetails}
				refetch={() => {
					refetchEmployee();
				}}
				customFields={
					employee?.employeeCustomFields?.custom_field.length
						? employee?.employeeCustomFields?.custom_field.map(
								(customField) => ({
									id: customField.id,
									label: customField.label,
									type: customField.type,
									validation: customField.validation,
									options: customField?.options || [],
									is_active: customField.is_active,
									index: customField.index,
								})
						  )
						: []
				}
			/>
		</>
	);
};

export default EmployeePersonalDetails;
