import { createTheme } from '@material-ui/core/styles';

export const Theme = createTheme({
	overrides: {
		// Layout theme

		RaLayout: {
			content: {
				paddingLeft: '20px !important',
			},
			contentWithSidebar: {
				background: '#F7F9FA',
				marginTop: '15px',
				height: '100%',

				// Menu Drawer
				'& .MuiDrawer-root': {
					background: '#F7F9FA',
					paddingTop: '20px',
					height: '100%',

					// TODO: Need to fix this and we should use class instead of tag
					'& > div > div': {
						position: 'relative',
						height: '100%',
					},
				},
			},
		},

		// App bar Theme
		RaAppBar: {
			toolbar: {
				paddingRight: '0px',
				boxShadow: '0px 3px 6px #0000000F',
				height: '63px',

				// Hide Refresh indicator
				'& button[aria-label="Refresh"]': {
					display: 'none',
				},
			},
			title: {
				color: 'white',
			},
			menuButton: {
				color: 'black',
			},
		},

		MuiDialog: {
			paper: {
				backgroundColor: '#FFFFFF',
			},
		},

		// Table theme
		MuiTableCell: {
			root: {
				borderBottom: 'none',
			},
		},
		RaList: {
			content: {
				boxShadow: 'none',
				backgroundColor: '#F7F9FA',
			},
		},
		MuiTable: {
			root: {
				borderCollapse: 'separate',
				borderSpacing: '0 5px',
				marginTop: '-5px',
			},
		},
		MuiTableRow: {
			root: {
				backgroundColor: '#FFFFFF',
				'&.MuiTableRow-hover:hover': {
					backgroundColor: '#FFFFFF',
				},
			},
			hover: {
				backgroundColor: '#FFFFFF',
				'&:hover': {
					backgroundColor: '#FFFFFF',
					boxShadow: '0px 8px 19px #0000000F',
					'& > td': {
						'& > span': {
							'& .MuiBox-root': {
								'& > button': {
									display: 'block',
								},
							},
						},
					},
				},
			},
		},
		RaDatagrid: {
			headerCell: {
				fontSize: '10px',
				fontFamily: 'Manrope-extrabold',
				color: '#5C5C5C',
				backgroundColor: '#F7F9FA',
				lineHeight: '16px',
			},
			row: {
				height: '40px',
				borderRadius: '4px',
			},
			rowCell: {
				fontFamily: 'Manrope-medium',
				fontSize: '12px',
				color: '#292929',

				// URL field link style
				'& div > a': {
					color: '#4285F4',
				},
				'& .MuiTypography-body2': {
					fontFamily: 'Manrope-medium',
					fontSize: '12px',
					color: '#292929',

					// Functional field reference link color
					'& > a': {
						color: '#4285F4',
					},
				},
				'& .RaLink-link': {
					'& .MuiTypography-body2': {
						fontFamily: 'Manrope-medium',
						fontSize: '12px',
						color: '#292929',
					},
				},
				'& > span': {
					fontFamily: 'Manrope-medium',
					fontSize: '12px',
					color: '#292929',
				},
				'& .RaReferenceField': {
					link: {
						color: '#4285F4',
					},
				},
				'& > a': {
					color: '#4285F4',
				},
			},
		},

		// Table Pagination
		MuiTablePagination: {
			toolbar: {
				backgroundColor: '#F7F9FA',
			},
			caption: {
				fontFamily: 'Manrope-medium',
				fontSize: '12px',
			},
			select: {
				fontFamily: 'Manrope-medium',
				fontSize: '12px',
				border: '1px solid #E0E0E0',
				borderRadius: '2px',
			},
		},
		RaPaginationActions: {
			actions: {
				'& > button': {
					'& .MuiButton-label': {
						fontFamily: 'Manrope-medium',
						fontSize: '12px',
					},
				},
			},
		},

		// Table Toolbar actions
		RaTopToolbar: {
			root: {
				'& .MuiBox-root': {
					// Create button
					'& .MuiButton-containedPrimary': {
						minWidth: '140px',
						height: '36px',
						background: '#4285F4 0% 0% no-repeat padding-box',
						boxShadow: '0px 4px 12px #4285F43B',
						borderRadius: '4px',
						font: 'normal normal bold 12px/37px Manrope',
					},
					'& .MuiButton-textPrimary': {
						minWidth: '140px',
						height: '36px',
						background: '#4285F4 0% 0% no-repeat padding-box',
						boxShadow: '0px 4px 12px #4285F43B',
						borderRadius: '4px',
						font: 'normal normal bold 12px/37px Manrope',
						color: '#FFFFFF',
						marginRight: '10px',
					},
					'& > button': {
						'&:hover': {
							background: '#5A98FF',
							color: 'white',
						},
					},
					'& > a': {
						'&:hover': {
							background: '#5A98FF',
							color: 'white',
						},
					},
				},
			},
		},

		MuiInputBase: {
			root: {
				background: '#FFFFFF',
			},
		},

		// Chip style
		MuiChip: {
			label: {
				color: '#4285F4',
				fontFamily: 'Manrope-semibold',
				fontSize: '10px',
			},
			deleteIcon: {
				color: '#4285F4',
				width: '16px',
				height: '16px',
			},
			outlined: {
				minWidth: '65px',
				height: '20px',
				border: '1px solid #E0E0E0',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				borderRadius: '4px',
			},
		},
		MuiAppBar: {
			colorSecondary: {
				boxShadow: 'none',
				backgroundColor: '#ffffff',
			},
		},

		// Reference field link style
		RaReferenceField: {
			link: {
				color: '#4285F4',
			},
		},

		// Tooltip style
		MuiTooltip: {
			tooltip: {
				fontFamily: 'Manrope-Medium',
				fontSize: '12px',
				backgroundColor: '#292929',
				border: '1 px solid #F7F9FA',
				color: '#FFFFFF',
				boxShadow: '0px 36px 60px #0000000F',
			},
		},
	},
	palette: {
		primary: {
			main: '#3f51b5',
		},
	},
});

export const modalFormTheme = createTheme({
	overrides: {
		RaFormInput: {
			input: {
				width: '100%',
			},
		},
		MuiMenuItem: {
			root: {
				fontSize: '12px',
			},
		},
		MuiFilledInput: {
			root: {
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				height: '36px',
				backgroundColor: '#FFFFFF 0% 0% no-repeat padding-box',
				'&:hover': {
					backgroundColor: '#FFFFFF',
				},
				'&$focused': {
					backgroundColor: '#FFFFFF',
				},
			},
			inputMarginDense: {
				paddingBottom: '0px',
				paddingTop: '0px !important',
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			inputMultiline: {
				height: '100px !important',
				padding: '0px 10px 0px 10px',
				overflowY: 'auto !important',
			},
			multiline: {
				padding: '0px !important',
				height: '120px !important',
			},
			adornedEnd: {
				fontSize: '12px',
				color: '#888888',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiCardContent: {
			root: {
				padding: 0,
			},
		},
		MuiFormControl: {
			root: {
				width: '100%',
			},
			marginDense: {
				marginTop: '0px',
				marginBottom: '0px',
			},
		},
		MuiInputBase: {
			root: {
				fontSize: '14px',
			},
			input: {
				font: 'normal normal medium Manrope',
				letterSpacing: '0px',
				color: '#292929',
				fontSize: '12px !important',
			},
		},
		MuiSelect: {
			selectMenu: {
				padding: '0px 0px 0px 10px',
			},
		},
		MuiTypography: {
			body1: {
				font: 'normal normal bold 12px/37px Manrope',
				fontSize: '12px !important',
				letterSpacing: '0px',
			},
		},
		MuiFormHelperText: {
			root: {
				fontSize: '10px !important',
			},
			marginDense: {
				marginTop: '0px',
			},
		},
		MuiRadio: {
			colorPrimary: {
				'&.Mui-checked': {
					color: '#4285F4',
				},
			},
		},
		RaRadioButtonGroupInput: {
			label: {
				margin: '0px',
			},
		},
		RaAutocompleteArrayInput: {
			chipContainerFilled: {
				margin: '0px',
			},
			inputRootFilled: {
				minHeight: '36px',
				height: 'auto',
			},
		},
		MuiChip: {
			label: {
				color: '#4285F4',
				fontFamily: 'Manrope-semibold',
				fontSize: '10px',
			},
			deleteIcon: {
				color: '#4285F4',
				width: '16px',
				height: '16px',
			},
			root: {
				minWidth: '65px',
				height: '20px',
				border: '1px solid #E0E0E0',
				background: '#FFFFFF',
				borderRadius: '4px',
			},
		},
	},
});
// Reusable theme for opportunity forms

export const currencySelectBox = createTheme({
	overrides: {
		MuiFilledInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			root: {
				background: '#ECF1F4 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				width: '80px',
				height: '36px',
				fontFamily: 'Manrope-Medium',
				fontSize: '12px',
				color: '#5C5C5C',
				paddingBottom: '13px',
				borderTopRightRadius: '0 !important',
			},
		},
	},
});

export const currencyTextBox = createTheme({
	overrides: {
		MuiFilledInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			root: {
				backgroundColor: 'white !important',
				border: '1px solid #E0E0E0',
				height: '36px',
				width: '95%',
				borderTopLeftRadius: '0 !important',
				fontFamily: 'Manrope-Medium',
				fontSize: '12px',
				color: '#5C5C5C',
				paddingBottom: '8px',
			},
		},
	},
});

export const accordionMenuTheme = createTheme({
	overrides: {
		MuiAccordion: {
			root: {
				'&.Mui-expanded': {
					margin: '8px 0px',
					'&:first-child': {
						borderTopLeftRadius: '2px',
						borderTopRightRadius: '2px',
					},
					'&:last-child': {
						borderBottomLeftRadius: '2px',
						borderBottomRightRadius: '2px',
					},
				},
				'&:before': {
					height: '0px',
				},
			},
			rounded: {
				marginBottom: '8px',
				boxShadow: 'none',
				width: '224px',
				borderRadius: '2px',
				'&:first-child': {
					borderTopLeftRadius: '2px',
					borderTopRightRadius: '2px',
				},
				'&:last-child': {
					borderBottomLeftRadius: '2px',
					borderBottomRightRadius: '2px',
				},
			},
		},
		MuiAccordionSummary: {
			root: {
				minHeight: 0,
				padding: '0px 10px',
				height: '40px',
				color: '#292929',
				'&.Mui-expanded': {
					minHeight: 0,
					height: '40px',
				},
			},
			content: {
				margin: 0,
				'& > p': {
					fontSize: '10px',
					fontFamily: 'Manrope-extrabold',
					textTransform: 'uppercase',
				},
				'&.Mui-expanded': {
					margin: '0px',
					color: '#4285F4',
				},
			},
		},
		MuiAccordionDetails: {
			root: {
				padding: '0px',
				display: 'block',
				'& .makeStyles-tabs': {
					borderRight: 'none',
				},
			},
		},
		MuiSvgIcon: {
			root: {
				width: '0.8em',
			},
		},

		// Tab
		MuiTab: {
			root: {
				fontFamily: 'Manrope-bold',
				fontSize: '12px',
				textTransform: 'none',
				minHeight: '30px',
			},
			textColorInherit: {
				color: '#5C5C5C',
				'&.Mui-selected': {
					background: '#4285F4',
					color: '#FFFFFF',
				},
			},
			wrapper: {
				alignItems: 'flex-start',
			},
		},
		MuiCollapse: {
			entered: {
				paddingBottom: '8px',
			},
		},
	},
});
// global theme for search filter in list and grid view

export const searchAutocompleteTheme = createTheme({
	overrides: {
		MuiInputBase: {
			input: {
				padding: '0px',
			},
		},
		MuiFilledInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			root: {
				width: '344px',
				height: '36px',
				backgroundColor: 'white !important',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				opacity: 1,
			},
			input: {
				fontSize: '12px',
				font: 'normal normal medium 12px/37px Manrope',
				letterSpacing: '0px',
				color: '#212121',
				opacity: 1,
			},
		},
		MuiInputLabel: {
			filled: {
				font: 'normal normal medium 12px/37px Manrope',
				letterSpacing: '0px',
				color: '#7C7C7C',
				opacity: 1,
				fontSize: '12px',
			},
		},
	},
});

export const searchBarTheme = createTheme({
	overrides: {
		// Search Input
		MuiInput: {
			root: {
				height: '36px',
				width: '100%',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&:after': {
					borderBottom: 'none',
				},
			},
			input: {
				paddingTop: '6px !important',
				height: '16px',
			},
		},

		// Search Icon
		MuiSvgIcon: {
			root: {
				color: '#7C7C7C',
				marginLeft: '14px',
				marginTop: '9px',
				width: '22px',
				height: '28px',
				fontSize: '17px',
			},
		},
	},
});

// theme for chart components in insight view
export const insightChartTheme = createTheme({
	overrides: {
		MuiPaper: {
			elevation1: {
				boxShadow: '0px 3px 6px #0000000F',
				borderRadius: '4px',
			},
		},
		MuiTypography: {
			h6: {
				fontSize: '14px',
				fontFamily: 'Manrope-extrabold',
			},
		},
	},
});

export const calenderTheme = createTheme({
	overrides: {
		MuiPickersCalendar: {
			week: {
				display: 'flex',
				justifyContent: 'space-between',
				marginTop: '12px',
			},
			transitionContainer: {
				marginTop: '12px',
				minHeight: '250px',
			},
		},
		MuiPickersDay: {
			daySelected: {
				backgroundColor: '#3f51b5',
				borderRadius: '18px',
				color: 'white',
				'&:hover': {
					backgroundColor: '#3f51b5',
					borderRadius: '8px',
					color: 'white',
				},
			},
		},
	},
});

export const customAutoCompleteSearchBarTheme = createTheme({
	overrides: {
		// Search Input
		MuiInput: {
			root: {
				height: '36px',
				width: '100%',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
				'& .MuiAutocomplete-endAdornment': {
					marginTop: '2px',
				},
				'& > input': {
					height: '23px',
				},
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&:after': {
					borderBottom: 'none',
				},
			},
			input: {
				height: '16px',
				marginLeft: '8px',
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		// Icon
		MuiSvgIcon: {
			root: {
				color: '#7C7C7C',
				marginLeft: '2px',
				marginRight: '2px',
				width: '16px',
				height: '16px',
				fontSize: '17px',
			},
		},
	},
});

//theme for dropdown with grey colored dropdown
export const customDropDownTheme = createTheme({
	overrides: {
		MuiMenuItem: {
			root: {
				fontSize: '12px',
			},
		},
		MuiFormHelperText: {
			root: {
				display: 'none',
			},
		},
		MuiFilledInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			root: {
				background: '#ECF1F4 0% 0% no-repeat padding-box !important',
				width: '120px',
				height: '33px',

				fontSize: '12px',
				fontFamily: 'Manrope-medium',
				borderRadius: '4px',
				'& > input': {
					paddingTop: '0px',
				},
			},
			inputMarginDense: {
				paddingTop: '0px',
				paddingBottom: '0px',
				fontSize: '14px',
			},
			input: {
				padding: '0px 0px 0px 8px',
			},
		},
		MuiInputLabel: {
			marginDense: {
				marginTop: '0px',
				fontSize: '12px',

				fontFamily: 'Manrope-medium',
			},
		},
		MuiFormControl: {
			root: {
				display: 'flex',
				justifyContent: 'end',
				alignItems: 'flex-end',
			},
		},
		MuiSelect: {
			select: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
	},
});

export const autoCompleteTheme = createTheme({
	overrides: {
		MuiInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
		},
	},
});
//reused prefix select box theme with clear button
export const prefixSelectBoxTheme = createTheme({
	overrides: {
		MuiSelect: {
			root: {
				background: 'transparent',
			},
		},
		MuiInputBase: {
			root: {
				background: 'transparent',
			},
		},
		MuiFilledInput: {
			backgroundColor: '#FFFFFF',
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			root: {
				background: 'transparent',
				backgroundColor: '#FFFFFF',
				border: '1px solid #E0E0E0',
				width: '100px',
				height: '36px',
				fontFamily: 'Manrope-Medium',
				fontSize: '12px',
				color: '#000000',
				paddingBottom: '13px',
				borderTopRightRadius: '0 !important',
				'&:hover': {
					backgroundColor: '#FFFFFF',
				},
			},
		},
		MuiInputAdornment: {
			root: {
				marginTop: '15px',
				marginLeft: '9px',
			},
		},
		MuiSvgIcon: {
			root: {
				marginLeft: '9px',
			},
		},
		MuiMenuItem: {
			root: {
				fontFamily: 'Manrope-Medium',
				fontSize: '12px',
			},
		},
	},
});

export const dataGridFilterDropdownTheme = createTheme({
	overrides: {
		MuiFormLabel: {
			root: {
				fontFamily: 'Manrope-medium',
			},
		},
	},
});


export const projectTaskAutoCompleteArrayInputTheme = createTheme({
	overrides: {
		RaFormInput: {
			input: {
				width: '100%',
			},
		},
		MuiMenuItem: {
			root: {
				fontSize: '12px',
			},
		},
		MuiFilledInput: {
			root: {
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				height: '36px',
				backgroundColor: '#FFFFFF 0% 0% no-repeat padding-box',
				'&:hover': {
					backgroundColor: '#FFFFFF',
				},
				'&$focused': {
					backgroundColor: '#FFFFFF',
				},
			},
			inputMarginDense: {
				paddingBottom: '0px',
				paddingTop: '0px !important',
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
		},
		MuiInputBase: {
			root: {
				fontSize: '14px',
			},
			input: {
				font: 'normal normal medium Manrope',
				letterSpacing: '0px',
				color: '#292929',
				fontSize: '12px !important',
			},
		},
		MuiFormHelperText: {
			root: {
				fontSize: '10px !important',
			},
			marginDense: {
				marginTop: '0px',
			},
		},
		RaAutocompleteArrayInput: {
			chipContainerFilled: {
				margin: '0px',
			},
			inputRootFilled: {
				minHeight: '40px',
				maxHeight: '100px',
				overflowY: 'auto'
			},
		},
		MuiChip: {
			label: {
				color: '#4285F4',
				fontFamily: 'Manrope-semibold',
				fontSize: '10px',
			},
			deleteIcon: {
				color: '#4285F4',
				width: '16px',
				height: '16px',
			},
			root: {
				minWidth: '65px',
				height: '20px',
				border: '1px solid #E0E0E0',
				background: '#FFFFFF',
				borderRadius: '4px',
			},
		},
	},
});