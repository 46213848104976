import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
	Box,
	Button,
	ThemeProvider,
	TextField as TextComponent,
	makeStyles,
	IconButton,
	CircularProgress,
	Tooltip,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import dayjs from 'dayjs';
import { head, isEmpty } from 'lodash';

import { modalFormStyle } from '../../../Layout/styles';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import { SearchIcon } from '../../../assets/SvgIcons';
import AppraisalForm from './AppraisalForm.component';
import { DataGridTheme } from '../pmsTheme';
import { searchBarTheme } from '../../../Layout/Theme.component';
import NoOfUsersIndicator from '../SharedComponents/NoOfUsersIndicator.component';
import {
	useGetAppraisalCyclesForExportQuery,
	useGetAppraisalCyclesQuery,
	useGetAppraisalFormOptionsQuery,
} from '../../../generated/graphql';
import { AppraisalFormInitialValue, AppraisalRow } from '../pms.module';
import { DEFAULT_RATING_AND_SCORE_RANGE, PMS_DATE_FORMAT } from '../constant';
import Loader from '../SharedComponents/Loader.component';
import { pmsDatagridStyle } from '../pms.styles';
import {
	exportToCsv,
	preventSubsequentClick,
} from '../../../Utils/string.util';
import { APPRAISAL_EXPORT_COLUMNS } from './constant';

const dataGridStyle = makeStyles(() => ({
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	statusChip: {
		width: '150px',
		padding: '10px',
		background: '#ebf3ff',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		cursor: 'pointer',
	},
	statusLabel: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	disabledButton: {
		color: 'grey',
		backgroundColor: 'lightgrey',
		minWidth: '70px',
		height: '34px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
		'&:hover': {
			background: 'lightgrey',
		},
	},
	text: {
		fontFamily: 'Manrope-medium',
		fontSize: '11px',
		cursor: 'pointer',
		fontWeight: 400,
	},
}));

const CustomSearchRender = (props: any) => {
	const { searchText, setSearchedText } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Cycles'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => {
						setSearchedText(event?.target?.value);
					}}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

function AppraisalCycleList() {
	const [searchedText, setSearchedText] = useState<string>('');
	const [isAppraisalFormShown, setIsAppraisalFormShown] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [appraisalRows, setAppraisalRows] = useState<AppraisalRow[]>([]);
	const [page, setPage] = useState<number>(0);
	const [row, setRow] = useState<AppraisalFormInitialValue>({});
	const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);

	const history = useHistory();
	const formStyles = modalFormStyle();
	const dataGridStyles = dataGridStyle();
	const listStyles = pmsDatagridStyle();

	// API CALLS
	const {
		data: appraisalCycles,
		loading: isAppraisalLoading,
		refetch: refetchAppraisalCycles,
	} = useGetAppraisalCyclesQuery({
		variables: {
			limit: rowsPerPage,
			offset: rowsPerPage * page,
			name: `%${searchedText}%`,
		},
	});
	const { data: appraisalCyclesExportList } =
		useGetAppraisalCyclesForExportQuery({
			variables: {
				name: `%${searchedText}%`,
			},
		});
	const { data: appraisalFormOptions } = useGetAppraisalFormOptionsQuery();

	useEffect(() => {
		if (!appraisalCycles) return;
		const data = appraisalCycles.pms_pms_appraisal_cycle.map(
			(appraisalCycle) => {
				const {
					enabled_modules,
					pms_appraisal_rating_scales,
					pms_appraisal_user_mappings_aggregate,
					pms_appraisal_status,
					...restAppraisalValues
				} = appraisalCycle;
				return {
					id: appraisalCycle.id,
					name: appraisalCycle.name,
					period: `${dayjs(appraisalCycle.start_date).format(
						PMS_DATE_FORMAT
					)} - ${dayjs(appraisalCycle.end_date).format(PMS_DATE_FORMAT)}`,
					resources:
						appraisalCycle.pms_appraisal_user_mappings_aggregate.aggregate
							?.count || 0,
					status: appraisalCycle.pms_appraisal_status.label,
					action: {
						enable_goal: appraisalCycle.enabled_modules.goal,
						enable_kra: appraisalCycle.enabled_modules.kra,
						...restAppraisalValues,
						ratingAndScoreRange: appraisalCycle.pms_appraisal_rating_scales.map(
							(rating) => {
								const { __typename, ...ratingValues } = rating;
								return { ...ratingValues, isEditable: false };
							}
						),
					},
				};
			}
		);
		setAppraisalRows(data);
	}, [appraisalCycles]);

	const columns = [
		{
			name: 'name',
			label: 'CYCLE',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${dataGridStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'period',
			label: 'PERIOD',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${dataGridStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'resources',
			label: 'RESOURCES',
			options: {
				customBodyRender: (value: any) => <NoOfUsersIndicator count={value} />,
			},
		},
		{
			name: 'status',
			label: 'STATUS',
			options: {
				customBodyRender: (value: any) => (
					<Tooltip title={value}>
						<div className={dataGridStyles.statusChip}>
							<p className={dataGridStyles.statusLabel}>{value}</p>
						</div>
					</Tooltip>
				),
			},
		},
		{
			name: 'action',
			label: 'ACTION',
			options: {
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Box className={listStyles.listEditIconContainer}>
							<IconButton
								className={listStyles.listEditIconButton}
								onClick={(event) => {
									event?.stopPropagation();
									const { __typename, ...selectedRow } = value;
									setRow(selectedRow);
									setIsAppraisalFormShown(true);
								}}
							>
								<EditIcon className={listStyles.editIcon} />
							</IconButton>
						</Box>
					);
				},
			},
		},
	];

	const options: MUIDataTableOptions = {
		filter: false,
		print: false,
		rowsPerPage: rowsPerPage,
		download: false,
		page: page,
		serverSide: true,
		rowsPerPageOptions: [5, 10, 20, 30],
		search: false,
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectableRowsHeader: false,
		count:
			appraisalCycles?.pms_pms_appraisal_cycle_aggregate.aggregate?.count || 0,
		textLabels: {
			body: {
				noMatch: 'No data found',
			},
		},
		onRowClick: (row: any, rowMeta) => {
			history.push(
				`/admin-pms/appraisal-cycle/${appraisalRows[rowMeta.dataIndex].id}`
			);
		},
		onChangeRowsPerPage: (numberOfRows: number) => {
			setRowsPerPage(numberOfRows);
			setPage(0);
		},
		onTableChange: (action: string, tableState: any) => {
			tableState.data.length === 0 ? setPage(0) : setPage(tableState.page);
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				default:
					return;
			}
		},
	};

	const getCheckInOption = () => {
		if (!appraisalFormOptions?.pms_pms_appraisal_check_in_interval) {
			return [];
		}
		const checkInOptions =
			appraisalFormOptions.pms_pms_appraisal_check_in_interval.map(
				(appraisalCheckInInterval) => ({
					id: appraisalCheckInInterval.id,
					name: appraisalCheckInInterval.label,
				})
			);
		return checkInOptions;
	};

	const getDefaultRatingScale = () => {
		if (appraisalFormOptions?.master_settings) {
			return head(appraisalFormOptions?.master_settings)?.value.length > 0
				? head(appraisalFormOptions?.master_settings)?.value?.map(
						(ratingScale: any) => ({
							id: ratingScale.id,
							label: ratingScale.name,
							min: ratingScale.min,
							max: ratingScale.max,
							isEditable: false,
						})
				  )
				: DEFAULT_RATING_AND_SCORE_RANGE;
		}
		return DEFAULT_RATING_AND_SCORE_RANGE;
	};
	const disableButton = () => {
		setButtonDisabled(false);
	};
	const preventClick = preventSubsequentClick(disableButton, 1000);

	const handleExport = () => {
		if (isEmpty(appraisalCyclesExportList?.pms_pms_appraisal_cycle)) {
			return;
		}

		const apprailsalExportData =
			appraisalCyclesExportList?.pms_pms_appraisal_cycle?.map((appraisal) => {
				return [
					appraisal?.name || '--',
					appraisal?.start_date
						? dayjs(appraisal?.start_date).format(PMS_DATE_FORMAT)
						: '--',
					appraisal?.end_date
						? dayjs(appraisal?.end_date).format(PMS_DATE_FORMAT)
						: '--',
					appraisal?.pms_appraisal_status?.label || '--',
					appraisal?.pms_appraisal_user_mappings_aggregate?.aggregate?.count ||
						'--',
					appraisal?.review_start_date
						? dayjs(appraisal?.review_start_date).format(PMS_DATE_FORMAT)
						: '--',
					appraisal?.review_end_date
						? dayjs(appraisal?.review_end_date).format(PMS_DATE_FORMAT)
						: '--',
					appraisal?.self_review_start_date
						? dayjs(appraisal?.self_review_start_date).format(PMS_DATE_FORMAT)
						: '--',
					appraisal?.self_review_end_date
						? dayjs(appraisal?.self_review_end_date).format(PMS_DATE_FORMAT)
						: '--',
					appraisal?.enable_salary_hike ? 'Yes' : 'No',
					appraisal?.enable_check_in ? 'Yes' : 'No',
					appraisal?.pms_appraisal_check_in_interval?.label || '--',
					appraisal?.description || '--',
				];
			}) || [];

		const exportData = [
			APPRAISAL_EXPORT_COLUMNS,
			...[...(apprailsalExportData || [])],
		];
		exportToCsv('Appraisal.csv', exportData);
	};
	const onExportClick = () => {
		if (!isButtonDisabled) {
			setButtonDisabled(true);
			handleExport();
			preventClick();
		}
	};
	return (
		<div>
			<div>
				<div>
					<Box
						display='flex'
						justifyContent='space-between'
						marginBottom='20px'
						marginTop='20px'
					>
						<Box display='flex' alignItems='center' sx={{ gridGap: '8px' }}>
							<Box>
								<CustomSearchRender
									searchText={searchedText}
									setSearchedText={setSearchedText}
								/>
							</Box>
							{isAppraisalLoading && (
								<Box>
									<Loader />
								</Box>
							)}
						</Box>
						<Box display='flex' sx={{ gridGap: '8px' }}>
							<Box>
								<Button
									className={
										isEmpty(appraisalRows) || isButtonDisabled
											? dataGridStyles.disabledButton
											: dataGridStyles.exportButton
									}
									onClick={onExportClick}
									endIcon={<ArrowUpwardIcon />}
									startIcon={
										isButtonDisabled && (
											<CircularProgress
												style={{
													height: '20px',
													width: '20px',
													color: '#FFFFFF',
												}}
											/>
										)
									}
								>
									Export
								</Button>
							</Box>
							<Box>
								<Button
									variant='contained'
									color='primary'
									className={formStyles.saveButton}
									startIcon={<AddIcon />}
									onClick={() => {
										setRow({});
										setIsAppraisalFormShown(true);
									}}
								>
									New Cycle
								</Button>
							</Box>
						</Box>
					</Box>
					<div>
						<ThemeProvider theme={DataGridTheme}>
							<MUIDataTable
								title='Appraisal Cycles'
								data={appraisalRows}
								columns={columns}
								options={options}
							/>
						</ThemeProvider>
						<AppraisalForm
							open={isAppraisalFormShown}
							onClose={() => {
								setRow({});
								setIsAppraisalFormShown(false);
							}}
							onSuccess={() => {
								refetchAppraisalCycles();
								setRow({});
								setIsAppraisalFormShown(false);
							}}
							defaultRatingScales={getDefaultRatingScale()}
							checkInOption={getCheckInOption()}
							initialAppraisalStatus={
								head(appraisalFormOptions?.initialAppraisalStatus)?.id || ''
							}
							initialUserAppraisalStatus={
								head(appraisalFormOptions?.userInitialAppraisalStatus)?.id || ''
							}
							initialValues={row}
							appraisalStatusOptions={
								appraisalFormOptions?.appraisalStatusOptions
									? appraisalFormOptions?.appraisalStatusOptions.map(
											(appraisalStatus) => {
												return {
													id: appraisalStatus?.id,
													name: appraisalStatus?.label,
													value: appraisalStatus?.value
												};
											}
									  )
									: []
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AppraisalCycleList;
