import dayjs from 'dayjs';
import { PMS_DATE_FORMAT } from './constant';

export const getEndDate = (date: Date) => {
	if (!date) {
		return '- -';
	}
	const today = dayjs().format('YYYY-MM-DD');
	const dateDiff = dayjs(date).diff(today);

	if (dateDiff < 0) {
		return dayjs(date).format(PMS_DATE_FORMAT);
	}

	if (dateDiff === 0) {
		return 'Today';
	}

	if (dateDiff <= 7) {
		return `${dateDiff} days left`;
	}

	return dayjs(date).format(PMS_DATE_FORMAT);
};
