import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Drawer, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { PMSSearchBar } from '../SharedComponents/PMSSearchBar.component';
import {
	useAddEmployeeForAppraisalMutation,
	useGetUserForAppraisalRecommendationQuery,
} from '../../../generated/graphql';
import Loader from '../SharedComponents/Loader.component';
import { head, some } from 'lodash';
import { useNotify } from 'react-admin';
import { EMPLOYEES_ADDED_SUCCESSFULLY } from '../constant';

const drawerStyle = makeStyles({
	drawerContainer: {
		width: '500px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		opacity: 1,
		padding: '50px 40px 0px 40px',
	},
	drawerHeading: {
		fontSize: '14px',
		textAlign: 'left',
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		textTransform: 'uppercase',
		paddingBottom: '18px',
	},
	HeaderSection: {
		display: 'flex',
		justifyContent: 'space-between',
		borderBottom: '1px solid #E0E0E0',
	},
	resetButton: {
		textDecoration: 'underline',
		font: 'normal normal bold 12px/23px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		opacity: 1,
		cursor: 'pointer',
	},
	IncludeAllButton: {
		width: '120px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		opacity: 1,
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		fontSize: '12px',
		'&:hover': {
			color: '#FFFF',
			background: '#366CC6',
		},
	},
	IncludeButton: {
		width: '100px',
		height: '28px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		opacity: 1,
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		fontSize: '12px',
		'&:hover': {
			color: '#FFFF',
			background: '#366CC6',
		},
		display: 'none',
	},
	buttonContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '20px',
		marginLeft: '10px',
		justifyContent: 'space-between',
	},
	disabledButton: {
		width: '120px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		opacity: 0.5,
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF !important',
		textTransform: 'none',
		fontSize: '12px',
	},
	rowContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		border: '1px solid #E0E0E0',
		height: '50px',
		padding: '0px 10px 0px 10px',
	},
	userProfileContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	userProfile: {
		width: '30px',
		height: '30px',
		borderRadius: '100px',
		backgroundColor: '#13299B',
		color: '#FFFFFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontFamily: 'Manrope-bold',
		textTransform: 'uppercase',
		marginRight: '10px',
	},
	userName: {
		fontFamily: 'Manrope-bold',
	},
	userEmail: {
		fontFamily: 'Manrope-medium',
		fontSize: '10px',
		color: '#5c5c5c',
	},
});

interface IncludeEmployeeDrawerProps {
	open: boolean;
	close: () => void;
	appraisalUsersIds: any[];
	appraisalCycleId: any;
	onSuccess: () => void;
}

interface Data {
	__typename?: 'user';
	id: any;
	email: string;
	full_name?: string | null | undefined;
	user_type?: any | null | undefined;
	employee?:
		| {
				__typename?: 'employee';
				reportsTo?: { __typename?: 'user'; id: any } | null | undefined;
		  }
		| null
		| undefined;
	contractors: Array<{
		__typename?: 'contractor';
		reportee?: { __typename?: 'user'; id: any } | null | undefined;
	}>;
}

function IncludeEmployeeDrawer(props: IncludeEmployeeDrawerProps) {
	const { open, close, appraisalUsersIds, appraisalCycleId, onSuccess } = props;
	const drawerStyles = drawerStyle();
	const notify = useNotify();

	// State
	const [searchedText, setSearchedText] = useState<string>('');
	const [selectedUsers, setSelectedUsers] = useState<Data[]>([]);
	const [data, setData] = useState<Data[]>([]);

	// API
	const { data: user, loading: isUsersLoading } =
		useGetUserForAppraisalRecommendationQuery({
			variables: {
				excludedUserIds: appraisalUsersIds || [],
				searchText: `%${searchedText}%`,
			},
		});

	const [addUsersToAppraisalCycle, { loading: isEmployeeMapLoading }] =
		useAddEmployeeForAppraisalMutation();

	useEffect(() => {
		if (!user) {
			return;
		}
		setData(user.user);
	}, [user]);

	const handleClose = () => {
		setSearchedText('');
		close();
	};

	const handleIncludeUsers = () => {
		if (selectedUsers.length === 0 || !user?.userInitialAppraisalStatus) return;

		const userInitialAppraisalStatus = head(
			user.userInitialAppraisalStatus
		)?.id;
		const payload = selectedUsers.map((user) => ({
			user_id: user.id,
			appraisal_cycle_id: appraisalCycleId,
			user_appraisal_status_id: userInitialAppraisalStatus,
			reviewer:
				user.user_type === 'employee'
					? user.employee?.reportsTo?.id
					: head(user.contractors)?.reportee?.id,
		}));

		addUsersToAppraisalCycle({
			variables: {
				data: payload,
			},
		}).then((res) => {
			if (!res.errors) {
				setSearchedText('');
				notify(EMPLOYEES_ADDED_SUCCESSFULLY);
				setSelectedUsers([]);
				setData([]);
				onSuccess();
			}
		});
	};

	return (
		<Drawer open={open} anchor='right' onClose={handleClose}>
			<div className={drawerStyles.drawerContainer}>
				<Box display='flex' flexDirection='column'>
					<Box className={drawerStyles.HeaderSection}>
						<p className={drawerStyles.drawerHeading}>Include Employees</p>
						<CloseIcon
							style={{
								cursor: 'pointer',
							}}
							onClick={handleClose}
						/>
					</Box>
				</Box>
				<div className={drawerStyles.buttonContainer}>
					<PMSSearchBar
						searchText={searchedText}
						setSearchedText={setSearchedText}
						placeHolder={'Search Employee'}
					/>
				</div>
				<div className={drawerStyles.buttonContainer}>
					<Box display={'flex'} alignItems={'center'}>
						<Checkbox
							name='checkedB'
							color='primary'
							checked={data.length === selectedUsers.length}
							onChange={(event) => {
								if (event?.currentTarget?.checked) {
									setSelectedUsers(data);
									return;
								}
								setSelectedUsers([]);
							}}
						/>
						<p>Select All</p>
					</Box>
					<Button
						disabled={selectedUsers.length === 0}
						className={
							selectedUsers.length === 0 || isEmployeeMapLoading
								? drawerStyles.disabledButton
								: drawerStyles.IncludeAllButton
						}
						onClick={handleIncludeUsers}
						startIcon={isEmployeeMapLoading && <Loader />}
					>
						{`${
							selectedUsers.length === 0
								? 'Include'
								: `Include(${selectedUsers.length})`
						} `}
					</Button>
				</div>

				<div
					style={{
						marginTop: '20px',
						maxHeight: '650px',
						overflowY: 'auto',
					}}
				>
					{isUsersLoading ? (
						<Loader />
					) : (
						data.map((user) => (
							<div key={user.id} className={drawerStyles.rowContainer}>
								<div className={drawerStyles.userProfileContainer}>
									<Checkbox
										name='checkedB'
										color='primary'
										checked={some(selectedUsers, { ...user })}
										onChange={(event) => {
											if (event?.currentTarget?.checked) {
												setSelectedUsers((prev) => [...prev, { ...user }]);
												return;
											}
											const updatedUsers = selectedUsers.filter(
												(usrId) => usrId.id !== user.id
											);
											setSelectedUsers(updatedUsers);
										}}
									/>
									<div className={drawerStyles.userProfile}>
										{user?.full_name ? user?.full_name.split('')[0] : '-'}
									</div>
									<div>
										<p className={drawerStyles.userName}>
											{user?.full_name || '- -'}
										</p>
										<p className={drawerStyles.userEmail}>{user.email}</p>
									</div>
								</div>
							</div>
						))
					)}
				</div>
			</div>
		</Drawer>
	);
}

export default IncludeEmployeeDrawer;
