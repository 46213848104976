export const timeSheetExportColumns: string[] = [
	'RESOURCE ID',
	'RESOURCE NAME',
	'EMAIL',
	'RESOURCE TYPE',
	'LOCATION',
	'REPORTING TO',
	'REPORTING TO EMAIL',
	'DEPARTMENT',
	'PROJECT NAME',
	'PROJECT OWNER',
	'PROJECT OWNER EMAIL',
	'PROJECT STATUS',
	'PROJECT START DATE',
	'PROJECT END DATE',
	'TASK NAME',
	'DATE',
	'HOURS ENTERED',
	'HOURS ENTERED(hh:mm)',
	'APPROVAL STATUS',
	'RESOURCE COST',
	'TOTAL COST',
	'NOTES',
];
export const timeSheetExportColumnsWithoutLocation: string[] = [
	'RESOURCE ID',
	'RESOURCE NAME',
	'EMAIL',
	'RESOURCE TYPE',
	'REPORTING TO',
	'REPORTING TO EMAIL',
	'DEPARTMENT',
	'PROJECT NAME',
	'PROJECT OWNER',
	'PROJECT OWNER EMAIL',
	'PROJECT STATUS',
	'PROJECT START DATE',
	'PROJECT END DATE',
	'TASK NAME',
	'DATE',
	'HOURS ENTERED',
	'HOURS ENTERED(hh:mm)',
	'APPROVAL STATUS',
	'RESOURCE COST',
	'TOTAL COST',
	'NOTES',
];

export const projectRisk = [
	{ id: 'Amber', name: 'Amber' },
	{ id: 'Green', name: 'Green' },
	{ id: 'Red', name: 'Red' },
];
export const START_DATE_ERROR =
	'Start date should not be greater than end date';

export const DATE_RANGE_ERROR = 'Date range should not be more than 30 days';

export const defaultersListColumns: string[] = [
	'RESOURCE ID',
	'RESOURCE NAME',
	'EMAIL',
	'RESOURCE TYPE',
	'STATUS',
	'REPORTING TO',
	'REPORTING TO EMAIL',
	'DEPARTMENT',
	'PROJECTS',
	'TOTAL HOURS',
	'UTILIZATION',
];

export const defaultersListColumnsWithLocation: string[] = [
	'RESOURCE ID',
	'RESOURCE NAME',
	'EMAIL',
	'RESOURCE TYPE',
	'LOCATION',
	'STATUS',
	'REPORTING TO',
	'REPORTING TO EMAIL',
	'DEPARTMENT',
	'PROJECTS',
	'TOTAL HOURS',
	'UTILIZATION',
];

export const uuidValidator =
	/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const ALL_PROJECTS = 'All';
export const EXPIRED_PROJECTS = 'Expired';
export const EXPIRED_PROJECTS_IN_N_DAYS = 'Expiring in 10 days';

export const PROJECT_LIST_COLUMNS: string[] = [
	'NAME',
	'PREFIX',
	'TYPE',
	'LABEL',
	'RISK',
	'PROJECT OWNER',
	'OWNER EMAIL',
	'PRESALES OWNER',
	'PRESALES OWNER EMAIL',
	'STATUS',
	'START DATE',
	'END DATE',
	'COMPANY',
	'EXECUTION TYPE',
	'ESTIMATED HOURS',
	'PLANNED HOURS',
	'ACTUAL HOURS',
	'CONTRACT VALUE',
	'EFFORT DEVIATION(%)',
	'CONVERSION PROBABILITY(%)',
	'TASK PROGRESS(%)',
	'TASK',
	'MILESTONE PORGRESS(%)',
	'MILESTONE',
	'RESOURCE COUNT',
	'RESOURCES',
	'TOTAL COST',
	'BILLABLE',
	'COUNTRY',
	'DESCRIPTION',
];

export const PROJECT_DELETION_WARNING_MESSAGE = `You are about to permanently delete the project and all of its associated data including tasks, timesheet, resource allocations, milestones and documents.`;
export const DELETE_PROJECT_SUCCESS_MESSAGE = 'Project deleted successfully';
export const DELETE_PROJECT_FAIL_MESSAGE = 'Project deletion failed';

export const COMMENT_CREATE_MESSAGE = 'Comment added successfully';

export const COMMENT_UPDATE_MESSAGE = 'Comment updated successfully';

export const COMMENT_DELETED_MESSAGE = 'Comment deleted successfully';

export const COMMENT_CREATION_FAILED_MESSAGE = 'Comment creaion failed';

export const COMMENT_UPDATION_FAILED_MESSAGE = 'Comment updation failed';

export const COMMENT_DELETION_FAILED_MESSAGE = 'Comment deletion failed';

export const PROJECT_CLONE_SUCCESS_MESSAGE = 'Project cloned successfully';

export const PROJECT_CLONE_FAILED_MESSAGE = 'Cloning failed';

export const PROJECT_NAME_CONSTRAINT = 'UQ_fdf317ed422e8e036a1295d6447';

export const PROJECT_PREFIX_CONSTRAINT = 'project_prefix_key';

export const PROJECT_TAG_CONSTRAINT = 'project_tags_name_org_id_key';

export const breadCumData = [{ name: 'Projects', path: '/project' }];
export const timesheetTypes = [
	{ id: 'All', name: 'All' },
	{ id: 'timesheet', name: 'Normal Time' },
	{ id: 'overtime', name: 'Over Time' },
];
export const PROJECT_CONTAINER = {
	projects: 'projects',
	tasks: 'tasks',
	milestones: 'milestones',
	timesheet: 'timesheet',
	resourceRequest: 'resourceRequest',
};
export const RESOURCE_REQUEST_DUPLICATE_SKILL_MESSAGE =
	'Skill with selected skill level already exist';

export const riskStatusOptions = [
	{ id: 'Amber', name: 'Amber' },
	{ id: 'Green', name: 'Green' },
	{ id: 'Red', name: 'Red' },
];

export const PROJECT_EXPORT_MESSAGE = 'Export failed';
export const DATE_INFO = 'Enter date in YYYY-MM-DD format';
export const DEFAULT_LABEL_FOR_CUSTOM_FIELD_MEMU = 'Name';
