import axios from 'axios';

export const onSignOut = () =>
	axios.get(`${process.env.REACT_APP_REST_API_URL}/auth/logout`, {
		headers: {
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			'Content-Type': 'application/json',
		},
	});

export const retrievePermissions = () =>
	axios
		.get(`${process.env.REACT_APP_REST_API_URL}/permission/role`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		})
		.catch((error) => {
			if (error?.response?.statusText === 'Unauthorized') {
				localStorage.clear();
				window.location = '/';
			}
		});

export const uploadFile = (data) =>
	axios({
		method: 'post',
		url: `${process.env.REACT_APP_REST_API_URL}/files`,
		data: data,
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
	});

export const uploadProfilePic = (data) =>
	axios({
		method: 'post',
		url: `${process.env.REACT_APP_REST_API_URL}/files`,
		data: data,
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
	});

export const uploadData = (data) =>
	axios({
		method: 'post',
		url: `${process.env.REACT_APP_REST_API_URL}/data-upload`,
		data: data,
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
	});

export const exportLeavePolicyBalance = (data) =>
	axios({
		method: 'post',
		responseType: 'blob',
		url: `${process.env.REACT_APP_REST_API_URL}/settings/export-leave-policy-balance`,
		data: data,
		headers: {
			'Content-Disposition': 'attachment; filename=template.xlsx',
			'Content-Type':
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

			Accept:
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
		body: data,
	});

export const exportMyTeamLeavePolicyBalance = (data) =>
	axios({
		method: 'post',
		responseType: 'blob',
		url: `${process.env.REACT_APP_REST_API_URL}/settings/export-myteam-leave-policy-balance`,
		data: data,
		headers: {
			'Content-Disposition': 'attachment; filename=template.xlsx',
			'Content-Type':
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

			Accept:
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
		body: data,
	});

export const exportProjectListData = (data) =>
	axios({
		method: 'post',
		responseType: 'blob',
		url: `${process.env.REACT_APP_REST_API_URL}/project/export-project`,
		data: data,
		headers: {
			'Content-Type': 'application/json',

			Accept:
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
		body: data,
	});
