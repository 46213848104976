import React, { useState } from 'react';

import { ONLY_NUMBERS } from '../constant';
import { RatingAndScoreRange } from '../pms.module';

import { Button, IconButton, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';

const ratingStyle = makeStyles({
	addRatingContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	addRatingInput: {
		border: '1px solid #E0E0E0',
		width: '300px',
		height: '36px',
		borderRadius: '4px',
		fontSize: '14px',
		fontFamily: 'Manrope-medium',
		padding: '0px 0px 0px 10px',
	},
	ratingOuterContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	ratingActionContainer: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '20px',
	},
	ratingContainer: {
		padding: '10px',
		marginBottom: '10px',
		display: 'flex',
		alignItems: 'center',
		width: '400px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		justifyContent: 'space-between',
	},
	ratingLabel: {
		fontSize: '14px',
		fontFamily: 'Manrope-medium',
		maxWidth: '150px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	ratingValueContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '150px',
		justifyContent: 'space-between',
	},
	ratingEditIcon: {
		width: '18px',
		height: '18px',
		color: '#4285F4',
	},
	ratingRemoveIcon: {
		width: '18px',
		height: '18px',
		color: '#EA4335',
	},
	ratingEditIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '4px',
		width: '24px',
		height: '24px',
		cursor: 'pointer',
	},
	ratingRangeInput: {
		width: '30px',
		border: '1px solid',
		padding: '0px 0px 0px 4px !important',
		borderRadius: '4px',
	},
	disabledRangeInput: {
		width: '28px',
		border: 'none',
		padding: '0px 0px 0px 4px !important',
	},
	ratingLabelInput: {
		width: '150px',
		border: '1px solid',
		padding: '0px 0px 0px 4px !important',
		borderRadius: '4px',
	},
	disabledLabelInput: {
		width: '150px',
		border: 'none',
		padding: '0px 0px 0px 4px !important',
	},
	checkIcon: {
		color: '#34A853',
	},
	cancelIcon: {
		color: '#EA4335',
	},
});

const RatingAndScoreRangeEditor = (props: {
	onChange: (values: RatingAndScoreRange[]) => void;
	values: RatingAndScoreRange[];
	disabled: boolean;
}) => {
	const { onChange, values, disabled } = props;
	const styles = ratingStyle();
	const [ratingsAndScore, setRatingsAndScore] =
		useState<RatingAndScoreRange[]>(values);
	const [isAddRatingInputShown, setIsAddRatingInputShown] = useState(false);
	const [ratingInputValue, setRatingInputValue] = useState('');
	const [lastUpdatedValues, setLastUpdatedValues] =
		useState<RatingAndScoreRange[]>(values);

	const handleEditRating = (id: any) => {
		if (!id) return;
		const editableRow = ratingsAndScore.find((value) => value.id === id);

		if (editableRow) {
			const updatedValues = [
				...ratingsAndScore
					.filter((value) => value.id !== id)
					.map((v) => ({ ...v, isEditable: false })),
				{ ...editableRow, isEditable: true },
			].sort((a, b) => Number(b.max) - Number(a.max));
			setRatingsAndScore((prev) => updatedValues);
		}
	};

	const handleClose = () => {
		setRatingsAndScore(lastUpdatedValues);
		onChange(lastUpdatedValues);
	};

	const handleSave = () => {
		const updatedValues = ratingsAndScore.map((value) => ({
			...value,
			min: value.min === '' ? 0 : value.min,
			max: value.max === '' ? 0 : value.max,
			label:
				value.label.trim() === ''
					? lastUpdatedValues.find(
							(lastUpdatedValue) => lastUpdatedValue.id === value.id
					  )?.label || 'Rating'
					: value.label,
			isEditable: false,
		}));
		const sortedValues = updatedValues.sort(
			(a, b) => Number(b.max) - Number(a.max)
		);
		setRatingsAndScore(sortedValues);
		setLastUpdatedValues(sortedValues);
		onChange(sortedValues);
	};

	const handleRemoveRating = (id: any) => {
		if (!id) return;
		const updatedValues = ratingsAndScore.filter((value) => value.id !== id);
		setRatingsAndScore(updatedValues);
		setLastUpdatedValues(updatedValues);
		onChange(updatedValues);
	};

	const handleAddRating = () => {
		if (ratingInputValue === '') return;
		const updatedValues = [
			...ratingsAndScore,
			{
				id: ratingInputValue,
				label: ratingInputValue.trim(),
				min: 0.1,
				max: 1.0,
				isEditable: false,
			},
		];
		setRatingsAndScore(updatedValues);
		setLastUpdatedValues(updatedValues);
		setRatingInputValue('');
		setIsAddRatingInputShown(false);
	};

	return (
		<div>
			<div>
				{isAddRatingInputShown && (
					<div className={styles.addRatingContainer}>
						<input
							className={styles.addRatingInput}
							value={ratingInputValue}
							onChange={(e) => {
								if (e.target.value.trim().length > 20) return;
								setRatingInputValue(e.target.value);
							}}
						/>
						<div className={styles.ratingActionContainer}>
							<IconButton>
								<CheckCircleIcon
									className={styles.checkIcon}
									onClick={handleAddRating}
								/>
							</IconButton>
							<IconButton>
								<CancelIcon
									className={styles.cancelIcon}
									onClick={() => {
										setIsAddRatingInputShown(false);
									}}
								/>
							</IconButton>
						</div>
					</div>
				)}

				{!disabled && !isAddRatingInputShown && ratingsAndScore.length <= 9 && (
					<Button
						style={{
							color: '#4285F4',
							textDecoration: 'none',
						}}
						startIcon={<AddIcon />}
						onClick={() => {
							handleClose();
							setIsAddRatingInputShown(true);
						}}
					>
						Add Rating
					</Button>
				)}
			</div>
			{ratingsAndScore.length > 0 ? (
				ratingsAndScore.map((value: RatingAndScoreRange, index: number) => (
					<form>
						<div className={styles.ratingOuterContainer}>
							<div className={styles.ratingContainer} key={value.id}>
								<div className={styles.ratingLabel}>
									<input
										value={value.label}
										className={
											value.isEditable
												? styles.ratingLabelInput
												: styles.disabledLabelInput
										}
										type='text'
										disabled={!value.isEditable}
										onChange={(e) => {
											const row = ratingsAndScore.find(
												(v) => v.id === value.id
											);
											let rowIndex;
											ratingsAndScore.forEach((v, i) => {
												if (v.id === value.id) {
													rowIndex = i;
												}
											});
											if (row && rowIndex !== undefined) {
												const updatedRow = {
													...row,
													label: e.target.value.trim(),
												};
												const updatedValues = [
													...ratingsAndScore.filter((v) => v.id !== value.id),
												];
												updatedValues.splice(rowIndex, 0, updatedRow);
												setRatingsAndScore(updatedValues);
											}
										}}
									/>
								</div>
								<div className={styles.ratingValueContainer}>
									<div>
										<input
											className={
												value.isEditable
													? styles.ratingRangeInput
													: styles.disabledRangeInput
											}
											type='text'
											value={value.min}
											disabled={!value.isEditable}
											onChange={(e) => {
												if (e.target.value === '') {
													const row = ratingsAndScore.find(
														(v) => v.id === value.id
													);
													let rowIndex;
													ratingsAndScore.forEach((v, i) => {
														if (v.id === value.id) {
															rowIndex = i;
														}
													});
													if (row && rowIndex !== undefined) {
														const updatedRow = {
															...row,
															min: e.target.value,
														};
														const updatedValues = [
															...ratingsAndScore.filter(
																(v) => v.id !== value.id
															),
														];
														updatedValues.splice(rowIndex, 0, updatedRow);
														setRatingsAndScore(updatedValues);
													}
												}
												if (
													!ONLY_NUMBERS.test(e.target.value) ||
													e.target.value.length >= 4
												) {
													return;
												}

												const row = ratingsAndScore.find(
													(v) => v.id === value.id
												);
												let rowIndex;
												ratingsAndScore.forEach((v, i) => {
													if (v.id === value.id) {
														rowIndex = i;
													}
												});
												if (row && rowIndex !== undefined) {
													const updatedRow = {
														...row,
														min: e.target.value,
													};
													const updatedValues = [
														...ratingsAndScore.filter((v) => v.id !== value.id),
													];
													updatedValues.splice(rowIndex, 0, updatedRow);
													setRatingsAndScore(updatedValues);
												}
											}}
										/>
									</div>
									<div>
										<input
											className={
												value.isEditable
													? styles.ratingRangeInput
													: styles.disabledRangeInput
											}
											type='text'
											value={value.max}
											disabled={!value.isEditable}
											onChange={(e) => {
												if (e.target.value === '') {
													const row = ratingsAndScore.find(
														(v) => v.id === value.id
													);
													let rowIndex;
													ratingsAndScore.forEach((v, i) => {
														if (v.id === value.id) {
															rowIndex = i;
														}
													});
													if (row && rowIndex !== undefined) {
														const updatedRow = {
															...row,
															max: e.target.value,
														};
														const updatedValues = [
															...ratingsAndScore.filter(
																(v) => v.id !== value.id
															),
														];
														updatedValues.splice(rowIndex, 0, updatedRow);
														setRatingsAndScore(updatedValues);
													}
												}
												if (
													!ONLY_NUMBERS.test(e.target.value) ||
													e.target.value.length >= 4
												) {
													return;
												}

												const row = ratingsAndScore.find(
													(v) => v.id === value.id
												);
												let rowIndex;
												ratingsAndScore.forEach((v, i) => {
													if (v.id === value.id) {
														rowIndex = i;
													}
												});
												if (row && rowIndex !== undefined) {
													const updatedRow = {
														...row,
														max: e.target.value,
													};
													const updatedValues = [
														...ratingsAndScore.filter((v) => v.id !== value.id),
													];
													updatedValues.splice(rowIndex, 0, updatedRow);
													setRatingsAndScore(updatedValues);
												}
											}}
										/>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										{!disabled &&
											!isAddRatingInputShown &&
											!value.isEditable && (
												<div
													className={styles.ratingEditIconContainer}
													onClick={() => handleEditRating(value.id)}
												>
													<EditIcon className={styles.ratingEditIcon} />
												</div>
											)}

										{!disabled &&
											!isAddRatingInputShown &&
											ratingsAndScore.length > 3 && (
												<div
													className={styles.ratingEditIconContainer}
													onClick={() => {
														handleRemoveRating(value.id);
													}}
												>
													<CloseIcon className={styles.ratingRemoveIcon} />
												</div>
											)}
									</div>
								</div>
							</div>
							{value.isEditable && (
								<div className={styles.ratingActionContainer}>
									<IconButton>
										<CheckCircleIcon
											className={styles.checkIcon}
											onClick={() => handleSave()}
										/>
									</IconButton>
									<IconButton>
										<CancelIcon
											className={styles.cancelIcon}
											onClick={() => handleClose()}
										/>
									</IconButton>
								</div>
							)}
						</div>
					</form>
				))
			) : (
				<div>No Rating</div>
			)}
		</div>
	);
};

export default RatingAndScoreRangeEditor;
